import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';

function ToolTip({ id, value }) {
    const displayValue = value || "-";

    return (
        <div>
            <p
                className='ellipsisText'
                id={id||""}
                dangerouslySetInnerHTML={{ __html: displayValue }}
            />
            <UncontrolledTooltip
                placement="right"
                target={id||"noclass"}
                trigger="hover"
            >
                <div dangerouslySetInnerHTML={{ __html: displayValue }} />
            </UncontrolledTooltip>
        </div>
    );
}

export default ToolTip;
