import React from "react";

const AttachmentList = ({ attachments, taskName, openImageInNewTab, setSelectedAttachment, iamoState }) => {
  if (!attachments?.length) {
    return <p>No Attachments</p>;
  }

  const handleMoreAttachmentsClick = () => {
    setSelectedAttachment({
      taskName,
      attachments: attachments.slice(2),
    });
    iamoState.on();
  };

  const renderAttachment = (attachment, index, isLast) => {
    if (attachment?.files?.name) {
      return (
        <div
          key={`attachment-${index}`}
          onClick={() => openImageInNewTab(attachment?.files.key)}
          style={{ color: "#0000EE", cursor: "pointer" }}
        >
          {attachment?.files?.name}
          {!isLast && <span>,&nbsp;</span>}
        </div>
      );
    }
    return <p key={`attachment-${index}`}>No Attachments</p>;
  };

  return (
    <div style={{ display: "flex" }}>
      {attachments.length > 2 ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          {attachments.slice(0, 2).map((attachment, index) =>
            renderAttachment(attachment, index, index === attachments.slice(0, 2).length - 1)
          )}
          <span
            onClick={handleMoreAttachmentsClick}
            style={{ color: "#fff", cursor: "pointer" }}
            className="badge rounded-pill badge-xs bg-primary"
          >
            +{attachments.length - 2}
          </span>
        </div>
      ) : (
        attachments.map((attachment, index) =>
          renderAttachment(attachment, index, index === attachments.length - 1)
        )
      )}
    </div>
  );
};

export default AttachmentList;
