import React, { useContext, useEffect } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import { MaintenanceContext } from "../../../../providers/Maintenance.provider";
import CorrectiveListCompact from "../components/CorrectiveList";
import { CorrectiveContext, withCorrectiveProvider } from "../CorrectiveProvider";
import { useLocation, useHistory } from "react-router-dom";
import { EquipmentAndSpareContext } from "../../../equipment_spare/EquipmentAndSpareProvider";

const CorrectiveMaintenanceContainer = (props) => {
  const location = useLocation().search;
  const history = useHistory();
  const status = new URLSearchParams(location).get("status");
  const { loadEquipmentList } = useContext(EquipmentAndSpareContext);
  const { loadCorrectiveList, dashboardFilterStatus, loadAllCorrectiveTasks, 
    getTableSettings } = useContext(CorrectiveContext);
  const { loadFilterOptions, loadAllUsers } = useContext(MaintenanceContext);

  const fetchCorrectiveTasksData = async () => {
    const settings = await getTableSettings({
      entityType: "CORRECTIVE_MAINTENANCE",
    });

    if (settings) {
      if (dashboardFilterStatus || (!dashboardFilterStatus && !status)) {
        const queryParams = new URLSearchParams(location);
        if (queryParams.has("status")) {
          queryParams.delete("status");
          history.replace({
            search: queryParams.toString(),
          });
        }
        await loadCorrectiveList(settings, null, status);

      }
      await loadFilterOptions()
      if (status && !dashboardFilterStatus) {
        loadCorrectiveList(settings, null, status);
      }
    }
  };

  useEffect(() => {
    fetchCorrectiveTasksData()
  }, []);

  useEffect(() => {
    loadFilterOptions();
  }, []);

  useEffect(() => {
    loadEquipmentList()
    loadAllUsers()
    loadAllCorrectiveTasks()
  }, [])

  return (
    <React.Fragment>
      <Head title="Brighter App | Corrective Maintenance" />
      <Content>
        <CorrectiveListCompact />
      </Content>
    </React.Fragment>
  );
};

export default withCorrectiveProvider(CorrectiveMaintenanceContainer);
