/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Content from "../../../../../layout/content/Content";
import Head from "../../../../../layout/head/Head";
import { withPreventativeTaskProvider, PreventativeTaskContext } from "../PreventativeTaskProvider";
import PreventativeTaskList from "../components/PreventativeTaskList";
import { useLocation, useHistory } from "react-router-dom";
import { MaintenanceContext } from "../../../../../providers/Maintenance.provider"

const PreventiveMaintenanceContainer = (props) => {
  const location = useLocation().search;
  const history = useHistory();
  const status = new URLSearchParams(location).get("status");
  const dashboard = new URLSearchParams(location).get("dashboard");
  const plantId = new URLSearchParams(location).get("plantId");
  const { loadPreventativeTasks, loadOptions, dashboardFilterStatus } = useContext(PreventativeTaskContext);
  const { getTableSettings } = useContext(PreventativeTaskContext);
  const { loadAllUsers } = useContext(MaintenanceContext);

  const fetchPreventativeTasksData = async () => {
    const settings = await getTableSettings({
      entityType: "PREVENTATIVE_MAINTENANCE",
    });

    if (settings) {
      if (dashboardFilterStatus || (!dashboardFilterStatus && !status)) {
        const queryParams = new URLSearchParams(location);
        if (queryParams.has("status")) {
          queryParams.delete("status");
          history.replace({
            search: queryParams.toString(),
          });
        }
        if (queryParams.has("plantId")) {
          queryParams.delete("plantId");
          history.replace({
            search: queryParams.toString(),
          });
        }
        await loadPreventativeTasks(settings,null,status);
      }
      await loadOptions()
      if (status && !dashboardFilterStatus) {
        await loadPreventativeTasks(settings, parseInt(plantId), status, dashboard);
      }
    }
  };

  useEffect(() => {
    fetchPreventativeTasksData()
  }, []);

  useEffect(() => {
    loadAllUsers()
    loadOptions();
  }, []);

  return (
    <React.Fragment>
      <Head title="Brighter App | Preventative Task" />
      <Content>
        <PreventativeTaskList />
      </Content>
    </React.Fragment>
  );
};

export default withPreventativeTaskProvider(PreventiveMaintenanceContainer);
