import {
  ADMIN_FORGOT_PASSWORD,
  ADMIN_LOGIN,
  USER_LOOKUP,
  ADMIN_RESET_PASSWORD,
  FORM_DATA,
  FORM_SUBMISSION_CREATE,
} from "../constants/endpoints";
import apiService from "../services/ApiService";

export async function loginUser(params) {
  const response = await apiService.post(ADMIN_LOGIN, {
    body: params,
  });
  return response;
}

export async function userLookup() {
  const response = await apiService.get(USER_LOOKUP);
  return response;
}

export async function forgotPassword(params) {
  const response = await apiService.post(ADMIN_FORGOT_PASSWORD, {
    body: params,
  });
  return response;
}

export async function resetPassword(resetPasswordToken, params) {
  const response = await apiService.put(ADMIN_RESET_PASSWORD, {
    body: params,
    pathParams: { resetPasswordToken },
  });
  return response;
}

export async function getForm(params) {
  const response = await apiService.get(FORM_DATA, {
    pathParams: params,
  });
  return response;
}
export async function createFormSubmissionapi(params) {
  const response = await apiService.post(FORM_SUBMISSION_CREATE, {
    body: params,
  });
  return response;
}