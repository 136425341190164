import React, { useContext, useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  Sidebar,
} from "../../../../components/Component";
import FilteredByList from "../../../../components/FilteredBy";
import Check from "../../../../components/check_roles/check";
import { confirmationSweetAlert } from "../../../../components/sweetAlert/SweetAlert";
import { errorToast, successToast } from "../../../../components/toastify/Toastify";
import { PREVENTATIVE_MASTER_FILTERS } from "../../../../constants/PreferenceKeys";
import Head from "../../../../layout/head/Head";
import ModalViewer from "../../../maintenance/ModalViewer";
import { MODE } from "../../../maintenance/preventative/constants";
import { PreventativeContext } from "../PreventativeProvider";
import { deletePreventativeMaster } from "../PreventativeRepository";
import PreventiveMaintenanceForm from "./PreventativeMaintenanceForm";
import PreventativeMasterFilter from "./PreventativeMasterFilter";
import ExportForm from "../../preventative_master_list/components/ExportForm";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const PreventiveMaintenanceList = () => {
  const preventativeContext = useContext(PreventativeContext);
  const {
    pagination,
    filterParams,
    preventativeMasters,
    plants,
    preventativeMasterLoading,
    handleApplyFilter,
    preventativeMasterListData,
    assetCategories,
    loadPreventativeMasters,
    preventativeMasterListId,
    showFilter,
    sfState,
    isFilterApplied,
  } = preventativeContext;
  const [sm, updateSm] = useState(false);
  const [mode, setMode] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [task, setTask] = useState("");
  const [currentTask, setCurrentTask] = useState({});
  const [view, setView] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  const toggle = () => setonSearch(!onSearch);
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const VIEW = {
    VIEW: "VIEW",
    CREATE: "CREATE",
    EDIT: "EDIT",
  };
  const { preventiveMasterListId } = useParams();

  const focusSearchRef = useRef();

  useEffect(() => {
    if (focusSearchRef.current) focusSearchRef.current.focus();
  });
  const toggleExportModal = () => {
    setIsExportModalOpen(!isExportModalOpen);
  };
  const createTask = () => {
    setMode(MODE.CREATE);
    setIsOpen(true);
    setTask("");
  };
  const onSuccessfulModal = (id) => {
    toggleModal();
    if (id) {
      loadPreventativeMasters(id);
    }
  };
  const createExport = () => {
    setView(VIEW.CREATE);
    setIsExportModalOpen(!isExportModalOpen);
    return <>Edit</>;
  };
  const getExportComponent = () => {
    if (view === VIEW.CREATE) {
      return (
        <ExportForm
          templateId={preventiveMasterListId}
          isFromMasterList={false}
          onSuccessfulModal={onSuccessfulModal}
          filterParams={filterParams}
          setIsExportModalOpen={setIsExportModalOpen}
        />
      );
    }
  };
  const getComponent = () => {
    return (
      <PreventiveMaintenanceForm
        onSuccessfulModal={onSuccessfulModal}
        mode={mode}
        taskId={task}
        setMode={setMode}
        setIsOpen={setIsOpen}
      />
    );
  };
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  const onSearchAction = () => {
    handleApplyFilter({ ...filterParams, taskText: onSearchText });
  };

  const currentItems = preventativeMasters?.items || [];

  useEffect(() => {
    const filters = localStorage.getItem(PREVENTATIVE_MASTER_FILTERS);
    if (filters) {
      const parseFilters = JSON.parse(filters);
      handleApplyFilter({
        ...parseFilters,
      });
    }
  }, []);
  const viewTask = (id) => {
    setTask(id);
    setMode(MODE.VIEW);
    toggleModal();
  };
  const handleNameSort = (sortOrder, field) => {
    handleApplyFilter({ ...filterParams, sortingOrder: sortOrder, sortingField: field });
  };

  const editTask = (id) => {
    setTask(id);
    setMode(MODE.EDIT);
    toggleModal();
  };
  const deleteFunc = async (taskId) => {
    try {
      await deletePreventativeMaster(taskId);
      loadPreventativeMasters(preventativeMasterListId);
      successToast({ description: "Successfully Deleted" });
    } catch (err) {
      errorToast({ description: "Error happened while deleting Preventative Master maintenance" });
    }
  };
  const handleDeleteConfirmation = (taskId) => {
    confirmationSweetAlert({
      id: taskId,
      handleConfirmation: deleteFunc,
    });
  };
  const assetCategorySelectedFilter = filterParams?.selectedAssetCategory?.map((item, key) => {
    return assetCategories?.[assetCategories?.findIndex((plant) => plant.value === item)];
  });

  const screenWidth = window.innerWidth;

  return (
    <React.Fragment>
      <Head title="Brighter App | Task" />
      {preventativeMasterLoading ? (
        <Skeleton count={10} className="w-100" />
      ) : (
        <>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                  {preventativeMasterListData.name}'s Template
                </BlockTitle>
                <BlockDes className="text-soft">
                <p>{preventativeMasterListData?.description}</p>
                </BlockDes>
                <BlockDes>
                  <p className="mt-2 fs-18px">
                    You have a total of {preventativeMasters?.pagination?.totalCount || 0} preventative master
                    templates.
                  </p>
                </BlockDes>
              </BlockHeadContent>
              <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <Button
                    className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                    onClick={() => updateSm(!sm)}
                  >
                    <Icon name="menu-alt-r"></Icon>
                  </Button>
                  <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                    <ul className="nk-block-tools g-3">
                      <Button
                        disabled={preventativeMasters?.pagination?.totalCount === 0}
                        className={`btn btn-white btn-outline-light`}
                        type="export"
                        isLoading={exportLoading}
                        onClick={createExport}
                      >
                        <Icon name="download-cloud"> </Icon>
                        <span>Export</span>
                      </Button>
                      <Check permission={["manageEntity"]}>
                        {/* <li>
                                        <a
                                            href={PREVENTATIVE_GENERATOR_PAGE}
                                            className="btn btn-outline-secondary"
                                        >
                                            <Icon name="plus"></Icon>
                                            <span>Generate Preventative Tasks</span>
                                        </a>
                                    </li> */}
                        <li className="nk-block-tools-opt">
                          <Button color="primary" onClick={createTask}>
                            <Icon name="plus"></Icon>
                            <span>Add Task</span>
                          </Button>
                        </li>
                      </Check>
                    </ul>
                  </div>
                </div>
              </BlockHeadContent>
            </BlockBetween>
            <div className="d-flex flex-wrap">
              <FilteredByList data={filterParams.selectedFrequency} title={"Frequency"} isMap={false} isStr={false} />
              <FilteredByList data={assetCategorySelectedFilter} title={"Asset Category"} isMap={true} isStr={false} />
              <FilteredByList data={filterParams.taskText} title={"Searched by"} isNotMap={true} isStr={true} />
            </div>
          </BlockHead>
          <Block>
            <DataTable className="card-stretch">
              {
                <div className="card-inner position-relative card-tools-toggle ">
                  <div className="card-title-group ">
                    <div className="card-tools"></div>
                    <div className="card-tools mr-n1 ">
                      <ul className="btn-toolbar gx-1">
                        <li>
                          <a
                            href="#search"
                            onClick={(ev) => {
                              ev.preventDefault();
                              toggle();
                            }}
                            className="btn btn-icon search-toggle toggle-search"
                          >
                            <Icon name="search"></Icon>
                          </a>
                        </li>
                        <li className="btn-toolbar-sep"></li>
                        <div tag="a" className="btn btn-icon " onClick={() => sfState.toggle()}>
                          {isFilterApplied ? <div className="dot dot-primary"></div> : null}
                          <Icon name="filter-alt"></Icon>
                        </div>
                      </ul>
                    </div>
                  </div>
                  <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                    <div className="card-body">
                      <div className="search-content">
                        <Button
                          className="search-back btn-icon toggle-search active"
                          onClick={() => {
                            setSearchText("");
                            handleApplyFilter({ ...filterParams, taskText: "" });
                            toggle();
                          }}
                        >
                          <Icon name="arrow-left"></Icon>
                        </Button>
                        <input
                          type="text"
                          className="border-transparent form-focus-none form-control"
                          placeholder="Search by task name and code.enter to search"
                          ref={(el) => {
                            focusSearchRef.current = !onSearch ? el : null;
                          }}
                          value={onSearchText}
                          onChange={(e) => onFilterChange(e)}
                          onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                              onSearchAction();
                            }
                            if (e.keyCode === 27) {
                              setSearchText("");
                              handleApplyFilter({ ...filterParams, searchText: "" });
                              toggle();
                            }
                          }}
                        />
                        <Button className="search-submit btn-icon">
                          <Icon name="search" onClick={onSearchAction}></Icon>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              }
                {preventativeMasterLoading ? <></> : <DataTableBody compact>
                <DataTableHead>
                  <DataTableRow>
                    <div className="d-flex" style={{ cursor: "pointer" }}>
                      <span
                        className="sub-text pr-1"
                        onClick={() => {
                          filterParams.sortingOrder === "ASC" && filterParams.sortingField === "title"
                            ? handleNameSort("DESC", "title")
                            : handleNameSort("ASC", "title");
                        }}
                      >
                        Task Name
                      </span>
                      <div className="d-flex flex-column justify-content-center">
                        <Icon
                          style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                          onClick={() => {
                            handleNameSort("ASC", "title");
                          }}
                          name={`${
                            filterParams.sortingOrder === "ASC" && filterParams.sortingField === "title"
                              ? "caret-up-fill "
                              : "caret-up"
                          } `}
                        ></Icon>

                        <Icon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleNameSort("DESC", "title");
                          }}
                          name={`${
                            filterParams.sortingOrder === "DESC" && filterParams.sortingField === "title"
                              ? "caret-down-fill"
                              : "caret-down"
                          }`}
                        ></Icon>
                      </div>
                    </div>
                  </DataTableRow>
                  <DataTableRow size="md">
                    <div className="d-flex" style={{ cursor: "pointer" }}>
                      <span
                        className="sub-text pr-1"
                        onClick={() => {
                          filterParams.sortingOrder === "ASC" && filterParams.sortingField === "code"
                            ? handleNameSort("DESC", "code")
                            : handleNameSort("ASC", "code");
                        }}
                      >
                        Code
                      </span>
                      <div className="d-flex flex-column justify-content-center">
                        <Icon
                          style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                          onClick={() => {
                            handleNameSort("ASC", "code");
                          }}
                          name={`${
                            filterParams.sortingOrder === "ASC" && filterParams.sortingField === "code"
                              ? "caret-up-fill "
                              : "caret-up"
                          } `}
                        ></Icon>

                        <Icon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleNameSort("DESC", "code");
                          }}
                          name={`${
                            filterParams.sortingOrder === "DESC" && filterParams.sortingField === "code"
                              ? "caret-down-fill"
                              : "caret-down"
                          }`}
                        ></Icon>
                      </div>
                    </div>
                  </DataTableRow>
                  <DataTableRow size="md">
                    <div className="d-flex" style={{ cursor: "pointer" }}>
                      <span
                        className="sub-text pr-1"
                        onClick={() => {
                          filterParams.sortingOrder === "ASC" && filterParams.sortingField === "frequency"
                            ? handleNameSort("DESC", "frequency")
                            : handleNameSort("ASC", "frequency");
                        }}
                      >
                        Frequency
                      </span>
                      <div className="d-flex flex-column justify-content-center">
                        <Icon
                          style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                          onClick={() => {
                            handleNameSort("ASC", "frequency");
                          }}
                          name={`${
                            filterParams.sortingOrder === "ASC" && filterParams.sortingField === "frequency"
                              ? "caret-up-fill "
                              : "caret-up"
                          } `}
                        ></Icon>

                        <Icon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleNameSort("DESC", "frequency");
                          }}
                          name={`${
                            filterParams.sortingOrder === "DESC" && filterParams.sortingField === "frequency"
                              ? "caret-down-fill"
                              : "caret-down"
                          }`}
                        ></Icon>
                      </div>
                    </div>
                  </DataTableRow>
                  <DataTableRow size="md">
                    <div className="d-flex" style={{ cursor: "pointer" }}>
                      <span
                        className="sub-text pr-1"
                        onClick={() => {
                          filterParams.sortingOrder === "ASC" && filterParams.sortingField === "asset_category_id"
                            ? handleNameSort("DESC", "asset_category_id")
                            : handleNameSort("ASC", "asset_category_id");
                        }}
                      >
                        Asset Category
                      </span>
                      <div className="d-flex flex-column justify-content-center">
                        <Icon
                          style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                          onClick={() => {
                            handleNameSort("ASC", "asset_category_id");
                          }}
                          name={`${
                            filterParams.sortingOrder === "ASC" && filterParams.sortingField === "asset_category_id"
                              ? "caret-up-fill "
                              : "caret-up"
                          } `}
                        ></Icon>

                        <Icon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleNameSort("DESC", "asset_category_id");
                          }}
                          name={`${
                            filterParams.sortingOrder === "DESC" && filterParams.sortingField === "asset_category_id"
                              ? "caret-down-fill"
                              : "caret-down"
                          }`}
                        ></Icon>
                      </div>
                    </div>
                  </DataTableRow>
                  <DataTableRow size="md">
                    <div className="d-flex" style={{ cursor: "pointer" }}>
                      <span
                        className="sub-text pr-1"
                        onClick={() => {
                          filterParams.sortingOrder === "ASC" && filterParams.sortingField === "description"
                            ? handleNameSort("DESC", "description")
                            : handleNameSort("ASC", "description");
                        }}
                      >
                        Description
                      </span>
                      <div className="d-flex flex-column justify-content-center">
                        <Icon
                          style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                          onClick={() => {
                            handleNameSort("ASC", "description");
                          }}
                          name={`${
                            filterParams.sortingOrder === "ASC" && filterParams.sortingField === "description"
                              ? "caret-up-fill "
                              : "caret-up"
                          } `}
                        ></Icon>

                        <Icon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleNameSort("DESC", "description");
                          }}
                          name={`${
                            filterParams.sortingOrder === "DESC" && filterParams.sortingField === "description"
                              ? "caret-down-fill"
                              : "caret-down"
                          }`}
                        ></Icon>
                      </div>
                    </div>
                  </DataTableRow>
                  <DataTableRow>{/* <span className="sub-text">Actions</span> */}</DataTableRow>
                </DataTableHead>

                <Sidebar toggleState={showFilter}>
                  <PreventativeMasterFilter plants={plants} />
                </Sidebar>

                {currentItems.length > 0
                  ? currentItems.map((item, i) => {
                      return (
                        <DataTableItem key={`${item.id}-preventative-masters`}>
                          <DataTableRow>
                            <div
                              className="user-card dropdown-toggle"
                              onClick={() => {
                                setCurrentTask(item);
                                viewTask(item.id);
                              }}
                            >
                              <div className="user-name">
                                <span
                                  data-toggle="tooltip"
                                  title={item.title}
                                  style={{
                                    width: screenWidth > 768 ? "350px" : "150px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    cursor: "pointer",
                                  }}
                                  className="tb-lead"
                                >
                                  {item.title}
                                </span>
                              </div>
                            </div>
                          </DataTableRow>
                          <DataTableRow size="md">
                            <span>{item.code || "-"}</span>
                          </DataTableRow>
                          <DataTableRow size="md">
                            <span>{item.frequency || "-"}</span>
                          </DataTableRow>
                          <DataTableRow size="md">
                            <span>{item.assetCategory?.name || "-"}</span>
                          </DataTableRow>
                          <DataTableRow size="md">
                            <span
                              data-toggle="tooltip"
                              title={item.description}
                              style={{
                                width: "150px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                cursor: "pointer",
                              }}
                              className="test-editor"
                              dangerouslySetInnerHTML={{__html:item?.description}}
                            />
                            
                          </DataTableRow>
                          <DataTableRow className="nk-tb-col-tools">
                            <ul className="nk-tb-actions gx-1">
                              <li>
                                <UncontrolledDropdown>
                                  <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                    <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu right>
                                    <ul className="link-list-opt no-bdr">
                                      <React.Fragment>
                                        <Check permission={["manageEntity"]}>
                                          <li /* className="nk-tb-action-hidden" */ onClick={() => editTask(item.id)}>
                                            <DropdownItem
                                              tag="a"
                                              href="#edit"
                                              onClick={(ev) => {
                                                ev.preventDefault();
                                              }}
                                            >
                                              <Icon name="edit-alt-fill"></Icon>
                                              <span>Edit Task</span>
                                            </DropdownItem>
                                          </li>
                                          <li
                                            /* className="nk-tb-action-hidden" */ onClick={() =>
                                              handleDeleteConfirmation(item.id)
                                            }
                                          >
                                            <DropdownItem
                                              tag="a"
                                              href="#edit"
                                              onClick={(ev) => {
                                                ev.preventDefault();
                                              }}
                                            >
                                              <Icon name="trash"></Icon>
                                              <span>Delete Task</span>
                                            </DropdownItem>
                                          </li>
                                        </Check>
                                      </React.Fragment>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </li>
                            </ul>
                          </DataTableRow>
                        </DataTableItem>
                      );
                    })
                  : null}
              </DataTableBody>}
              <div className="card-inner">
                {preventativeMasters.pagination && preventativeMasters.pagination.totalCount > 0 ? (
                  <PaginationComponent
                    itemPerPage={pagination.size}
                    totalItems={preventativeMasters.pagination.totalCount}
                    paginate={preventativeContext.updateShowListPage}
                    currentPage={preventativeMasters.pagination.currentPage}
                    paginatePage={preventativeContext.updateShowListSize}
                  />
                ) : (
                  !preventativeMasterLoading && (
                    <div className="text-center">
                      <span className="text-silent">No data found</span>
                    </div>
                  )
                )}
              </div>
            </DataTable>
          </Block>
          <ModalViewer
            title={`Preventative Master Template for  ${preventativeMasterListData.name}`}
            isOpen={isOpen}
            toggleModal={toggleModal}
            component={getComponent()}
          />
          <ModalViewer
            title="Preventative Master List - Select the Export Fields"
            isOpen={isExportModalOpen}
            size="md"
            toggleModal={toggleExportModal}
            component={getExportComponent()}
          />
        </>
      )}
    </React.Fragment>
  );
};
export default PreventiveMaintenanceList;
