import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { Card } from "reactstrap";
import './style.css'

export const DataTable = ({ className, bodyClassName, title, ...props }) => {
  return (
    <Card className={`${className ? className : ""}`}>
      <div className="card-inner-group" >
        {props.children}
      </div>
    </Card>
  );
};

export const DataTableTitle = ({ ...props }) => {
  return (
    <div className="card-inner position-relative card-tools-toggle">
      <div className="card-title-group">{props.children}</div>
    </div>
  );
};

export const DataTableBody = ({ compact, seperate, className, bodyclass, ...props }) => {
  const [calculatedHeight, setCalculatedHeight] = useState('auto');
  const innerContentRef = useRef(null);

  const innerclass = classNames({
    "nk-tb-list nk-tb-ulist": true,
    [`${bodyclass}`]: bodyclass,
    "is-compact": compact,
    "is-separate": seperate,
  });

  useEffect(() => {
    if (innerContentRef.current) {
      const innerContentHeight = innerContentRef.current.offsetHeight;
      if (innerContentHeight >= 703) {
        setCalculatedHeight('80vh');
      } else {
        setCalculatedHeight('auto'); 
      }
    }
  }, [props.children]);

  return (
    <div 
      className={`card-inner p-0 ${className ? className : ""}`} 
      style={{ overflow: "scroll", height: calculatedHeight }}
    >
      <div ref={innerContentRef} className={innerclass}>
        {props.children}
      </div>
    </div>
  );
};

export const DataTableHead = ({ ...props }) => {
  return <div className="nk-tb-item nk-tb-head">{props.children}</div>;
};

export const DataTableRow = ({ className, size, ...props }) => {
  const rowClass = classNames({
    "nk-tb-col": true,
    [`${className}`]: className,
    [`tb-col-${size}`]: size,
  });
  return <div className={rowClass} {...props}>{props.children}</div>;
};

export const DataTableItem = ({ className, ...props }) => {
  return <div className={`nk-tb-item ${className ? className : ""}`} {...props}>{props.children}</div>;
};
