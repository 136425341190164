import { format } from "date-fns";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "jspdf-autotable";
import React, { useContext, useEffect, useState } from "react";
import { ReactFormGenerator } from "react-form-builder2";
import "react-form-builder2/dist/app.css";
import Skeleton from "react-loading-skeleton";
import { useHistory, useParams } from "react-router-dom";
import { Card } from "reactstrap";
import { BlockBetween, BlockHead, BlockHeadContent, Button, Icon } from "../../../components/Component";
import Check from "../../../components/check_roles/check";
import Head from "../../../layout/head/Head";
import { FormManagementContext, withFormManagementProvider } from "../FormManagementProvider";
import { PDFExport } from "@progress/kendo-react-pdf";
import { infoToast, successToast } from "../../../components/toastify/Toastify";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function ViewForm() {
  const history = useHistory();
  const pdfExportRef = React.useRef(null);

  const { currentViewForm, loadForm, loadSubmittedForm, filterParams, submittedForms, formtitle } =
    useContext(FormManagementContext);
  const { formId, id } = useParams();
  const location = useLocation().search;
  const latestVersion = new URLSearchParams(location).get("version");
  const [sm, updateSm] = useState(false);

  useEffect(() => {
    loadForm(formId, latestVersion);
    loadSubmittedForm(formId, latestVersion, id);
  }, [filterParams]);

  const handleDownloadPdf = () => {
    infoToast({ description: "Download still in progress. Please wait until the download is complete" });
    if (pdfExportRef.current) {
      pdfExportRef.current.save();
    }
  };

  const createdBy = submittedForms?.items?.length
    ? `${submittedForms?.items[0]?.formUser?.firstName} ${submittedForms?.items[0]?.formUser?.lastName}`
    : "-";
  const createdAt = submittedForms?.items?.length
    ? format(new Date(submittedForms?.items[0]?.created_at || null), "dd/MM/yyyy HH:mm")
    : "-";
  const formName = submittedForms?.items?.length ? submittedForms?.items[0]?.forms?.title : "-";
  return (
    <React.Fragment>
      <Head title="Brighter App | Form | View" />
      <div style={{ padding: "3%" }}>
        <BlockBetween>
          <div>
            <Button onClick={history.goBack} color="light" outline className="bg-white d-none d-sm-inline-flex">
              <Icon name="arrow-left"></Icon>
              <span>Back</span>
            </Button>
          </div>
          <div style={{ marginTop: "2%" }} className="toggle-wrap nk-block-tools-toggle">
            <Button
              className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
              onClick={() => updateSm(!sm)}
            >
              <Icon name="menu-alt-r"></Icon>
            </Button>
            <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
              <ul className="nk-block-tools g-3">
                <Check permission={["getTickets", "manageTickets"]}>
                  <li>
                    <Button
                      onClick={() => {
                        handleDownloadPdf();
                      }}
                      className={`btn btn-white btn-outline-light ${false ? "disabled" : ""}`}
                      type="export"
                    >
                      <Icon name="download-cloud"> </Icon>
                      <span>Export</span>
                    </Button>
                  </li>
                </Check>
              </ul>
            </div>
          </div>
        </BlockBetween>
        <PDFExport fileName={formName} paperSize="A4" margin="2cm" ref={pdfExportRef}>
          <Card id="printableArea" style={{ padding: "2%", marginTop: "10px" }}>
            <BlockHead size="sm">
              <BlockBetween>
                <BlockHeadContent>
                  <div style={{ display: "flex" }}>
                    <span className="profile-ud-label">Form Name :</span>
                    <span className="profile-ud-value">{formName || "-"}</span>
                  </div>
                  <div style={{ display: "flex" }}>
                    <span className="profile-ud-label">Submitted By :</span>
                    <span className="profile-ud-value">{createdBy || "-"}</span>
                  </div>
                  <div style={{ display: "flex" }}>
                    <span className="profile-ud-label">Submitted At :</span>
                    <span className="profile-ud-value">{createdAt || "-"}</span>
                  </div>
                </BlockHeadContent>
                <BlockHeadContent></BlockHeadContent>
              </BlockBetween>
            </BlockHead>
            <hr style={{ marginTop: 0, marginTop: 0, color: "#c8d4e2" }} />
            {currentViewForm?.data && submittedForms?.items?.length ? (
              <ReactFormGenerator
                read_only={true}
                download_path=""
                answer_data={submittedForms?.items[0]?.rawData}
                data={currentViewForm?.data}
                hide_actions={true}
              />
            ) : (
              <Skeleton width={"100"} count={15} />
            )}
          </Card>
        </PDFExport>
      </div>
    </React.Fragment>
  );
}

export default withFormManagementProvider(ViewForm);
