/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import React, { useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Row, Col, FormGroup, Form, Label, CardBody, CardText } from "reactstrap";
import { Block, PreviewCard, Button, RSelect } from "../../../components/Component";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useBoolean from "../../../hooks/useBoolean";
import { RoleManagementContext } from "../RoleManagementProvider";
import { errorToast, successToast } from "../../../components/toastify/Toastify";
import Head from "../../../layout/head/Head";

const createSchema = yup.object({
  name: yup.string().required(),
  level: yup.number().required(),
  actions: yup.array().of(yup.string()).default([]),
});

const updateSchema = yup.object({
  name: yup.string().required(),
  level: yup.number().required(),
  actions: yup.array().of(yup.string()).default([]),
});

const RoleForm = (props) => {
  const { createRole, allRoles, roleActions,loadingRoleView } = useContext(RoleManagementContext);

  const [isEditMode, updateEditMode] = useBoolean(false);

  const roleFormRef = useForm({
    resolver: yupResolver(props.roleId ? updateSchema : createSchema),
  });
  const {
    setValue,
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    reset,
  } = roleFormRef;

  //ADDED COUNT FOR ROLE OPTION
  const rolesWithCount = allRoles.map((data) => {
    return { ...data, label: `${data?.label} (${data?.level})` };
  });
  const rolesWithLevel = allRoles.map((data) => {
    return { ...data, label: data?.level };
  });

  useEffect(() => {
    reset({ actions: [] });
  }, []);
  useEffect(() => {
    reset(props.currentViewRole);
    if (props.currentViewRole) updateEditMode.on();
  }, [props.currentViewRole]);

  const handleFormSubmit = (formData) => {
    if (props.roleId) {
      props
        .updateRole(props.currentViewRole.id, formData)
        .then((res) => {
          successToast({ description: "Successfully Updated" });
          if (props.onSuccessfulModal) props.onSuccessfulModal();
        })
        .catch((e) => {
          errorToast({ description: `${e}` });
        });
    } else {
      createRole(formData)
        .then((res) => {
          successToast({ description: "Successfully Created" });
          if (props.onSuccessfulModal) props.onSuccessfulModal();
        })
        .catch((e) => {
          errorToast({ description: `${e}` });
        });
    }
  };

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  const renderCreateButton = () => {
    return (
      <Button color="primary" size="lg" isLoading={loadingRoleView} 
      onClick={loadingRoleView ? null : handleSubmit(handleFormSubmit)}>
        Add
      </Button>
    );
  };

  const renderEditButton = () => {
    return (
      <>
        <Button color="primary" size="lg" isLoading={loadingRoleView} className="mr-1" 
        onClick={loadingRoleView ? null : handleSubmit(handleFormSubmit)}>
          Save Information
        </Button>
        <Button size="lg" color="outline-primary" onClick={() => props.onSuccessfulModal()}>
          Cancel
        </Button>
      </>
    );
  };

  const renderSaveButton = () => {
    if (isEditMode) {
      return renderEditButton();
    } else {
      return renderCreateButton();
    }
  };

  return (
    <div className="overflow-auto h-max-450px">
      <Head title={props.roleId ? "Brighter App | Role | Edit" : "Brighter App | Role | Create"} />

      <Block size="lg">
        <PreviewCard>
          <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
            <Row className="g-5" lg="12" md="12">
              <Col lg="6" md="6">
                <FormGroup>
                  <Label className="form-label" htmlFor="name">
                    <span style={{ color: "indianred" }}>&#42;</span>Name
                  </Label>
                  <div className="form-control-wrap">
                    <input {...register("name")} type="text" name="name" className="form-control" />
                  </div>
                  {errors.name && <span className="invalid text-danger">{errors.name?.message}</span>}
                </FormGroup>
              </Col>
              <Col lg="6" md="6">
                <FormGroup>
                  <Label className="form-label" htmlFor="level">
                    <span style={{ color: "indianred" }}>&#42;</span>Parent Level
                  </Label>
                  <div className="form-control-wrap">
                    <Controller
                      control={control}
                      name="level"
                      rules={{ required: true }}
                      render={({ field, ref }) => {
                        const options = isEditMode ? rolesWithLevel : rolesWithCount;
                        const selectedValue = options.find((e) => e.value === field.value);
                        return (
                          <RSelect
                            {...field}
                            ref={ref}
                            value={selectedValue}
                            isDisabled={props.currentViewRole && props.currentViewRole.level === 1}
                            placeholder="Select the Parent Level "
                            options={options}
                            onChange={(o) => setValue("level", o.value, { shouldValidate: true })}
                          />
                        );
                      }}
                    />
                    {errors.level && <span className="invalid text-danger">{errors.level?.message}</span>}
                  </div>
                </FormGroup>
              </Col>

              <Row className="match-height ">
                <Col lg="12" md="12" className="m-0 pb-0">
                  <Label className="form-label p-0">Actions</Label>
                </Col>
                {roleActions &&
                  roleActions.map((roleAction, key) => {
                    let rolesLength = false;
                    const selectedValue = getValues("actions").filter(
                      (e) => roleAction.actions && roleAction.actions.includes(e)
                    );
                    rolesLength = selectedValue.length === roleAction.actions.length;
                    let actionValues = [];
                    return (
                      <Col lg="6" md="6" sm="12" key={`${roleAction.entity} + ${key}`}>
                        <FormGroup>
                          <CardBody className="m-0">
                            <input
                              key={`${roleAction.entity} + ${key}`}
                              type="checkbox"
                              defaultChecked={rolesLength}
                              onChange={(e) => {
                                let temp = getValues().actions;
                                let currentActions = roleAction.actions?.map((action, key) => {
                                  return action;
                                });
                                if (e.target.checked) {
                                  setValue("actions", [...temp, ...currentActions]);
                                } else {
                                  setValue(
                                    "actions",
                                    temp.filter((e) => !currentActions.includes(e))
                                  );
                                }
                              }}
                            />
                            <label className="form-label ml-1" htmlFor="name">
                              {roleAction.entity}
                            </label>

                            {roleAction.actions.map((action, index) => {
                              return (
                                <CardText className="m-0">
                                  <input
                                    key={`${action}-${index}`}
                                    type="checkbox"
                                    value={action}
                                    id={`${action}-${index}`}
                                    {...register("actions")}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        actionValues.push(e.target.value);
                                        rolesLength = actionValues.length === roleAction.actions.length;
                                      } else {
                                        const presentData = actionValues.includes(e.target.value);
                                        if (presentData) {
                                          actionValues = actionValues.filter((d) => d !== e.target.value);
                                        }
                                      }
                                    }}
                                  />
                                  <Label className="ml-1" for={`${action}-${index}`} check>
                                    {action.replace(/^./, function (str) {
                                      return str.toUpperCase();
                                    })}
                                  </Label>
                                </CardText>
                              );
                            })}
                          </CardBody>
                        </FormGroup>
                      </Col>
                    );
                  })}
              </Row>

              <Col xl="12" className="d-flex justify-content-end">
                {renderSaveButton()}
              </Col>
            </Row>
          </Form>
        </PreviewCard>
      </Block>
    </div>
  );
};

export default RoleForm;
