import React, { useState } from "react";
import useBoolean from "../../hooks/useBoolean";
import {
  createClientAPI,
  getAdminClientList,
  getAdminClientListFilterOptions,
  getClientAPI,
  updateClientAPI,
  deleteClientAPI,
  archiveClientAPI,
  unarchiveClientAPI,
  getAdminpvPlantsList,
} from "./ClientMangementRepository";
import { getFile } from "../../repositories/FileRepository";

const initialPaginationState = {
  page: 1,
  size: 10,
};
const initialFilterOptions = {
  clientStatuses: [],
};
const initialFilterParams = {
  selectedClientStatus: [],
  isArchiveSelected: false,
  searchText: "",
  sortingOrder: "ASC",
  sortingField: "name",
  phoneNumber: "",
  mobileNumber: "",
  city: "",
  email: "",
};
export const ClientManagementContext = React.createContext();

export const ClientManagementProvider = (props) => {
  const [clientsList, setClientsList] = React.useState([]);
  const [pagination, setPagination] = React.useState(Object.assign({}, initialPaginationState));
  const [filterParams, setFilterParams] = React.useState(Object.assign({}, initialFilterParams));
  const [filterOptions, setFilterOptions] = React.useState(Object.assign({}, initialFilterOptions));
  const [currentViewClient, setCurrentViewClient] = React.useState(null);
  const [clientUsers, setClientUsers] = React.useState([]);
  const [plantsList, setPlantsList] = React.useState([]);
  const [clientPlantIds, setClientPlantIds] = React.useState([]);
  const [logoId, setLogoId] = React.useState("");
  const [logoAttachment, setLogoAttachment] = React.useState("");
  const [logoKey, setLogoKey] = useState("");

  //Loaders
  const [clientLoading, clientState] = useBoolean(false);
  const [plantLoading, plantState] = useBoolean(false);
  const [filtersLoading, flState] = useBoolean(false);
  const [loadingClientView, lswState] = useBoolean(false);

  const [showFilter, sfState] = useBoolean(false);

  const getClientListFilterParams = () => {
    const queryParams = { ...pagination };
    const {
      selectedClientStatus,
      isArchiveSelected,
      searchText,
      sortingField,
      sortingOrder,
      phoneNumber,
      mobileNumber,
      city,
      email,
    } = filterParams;
    if (selectedClientStatus) queryParams["clientStatus"] = selectedClientStatus;
    if (selectedClientStatus) queryParams["clientSearchText"] = searchText;
    if (isArchiveSelected !== null) queryParams["clientIsArchived"] = isArchiveSelected;
    if (sortingField) queryParams["sortingField"] = sortingField;
    if (sortingOrder) queryParams["sortingOrder"] = sortingOrder;
    if (phoneNumber) queryParams["phoneNumber"] = phoneNumber;
    if (mobileNumber) queryParams["mobileNumber"] = mobileNumber;
    if (city) queryParams["city"] = city;
    if (email) queryParams["email"] = email;

    return queryParams;
  };
  const loadFilterOptions = async () => {
    flState.on();

    try {
      const response = await getAdminClientListFilterOptions();
      setFilterOptions(response);
    } catch (e) {
      // HANDLE ERROR
    } finally {
      flState.off();
    }
  };

  const loadClientList = async () => {
    clientState.on();

    try {
      const filterParams = getClientListFilterParams();
      const response = await getAdminClientList(filterParams);
      setClientsList(response.clientList);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      clientState.off();
    }
  };
  const loadPvPlantList = async () => {
    plantState.on();

    try {
      const response = await getAdminpvPlantsList();
      setPlantsList(response);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      plantState.off();
    }
  };

  const handleApplyFilter = (selectedFilters) => {
    setFilterParams(selectedFilters);
  };
  const resetClientListFilter = () => setFilterParams(Object.assign({}, initialFilterParams));

  //CREATE CLIENT
  const createClient = async (createParams) => {
    try {
      lswState.on();
      await createClientAPI(createParams);
    } catch (e) {
      throw e;
    } finally {
      lswState.off();
    }
  };

  // VIEW CLIENT
  const loadClient = async (clientId) => {
    try {
      const response = await getClientAPI(clientId);
      setCurrentViewClient(response.client);
      setClientUsers(response.clientUsers);
      setClientPlantIds(response.plantIds);
      if (response.logoAttachment) {
        const logoPreviewUrl = await getFile(response.logoAttachment.files.key);
        setLogoAttachment(logoPreviewUrl);
        setLogoId(response.logoAttachment.files.id);
        setLogoKey(response.logoAttachment.files.key);
      }
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };

  //UPDATE CLIENT
  const updateClient = async (clientId, updateParams) => {
    try {
      lswState.on();
      await updateClientAPI(clientId, updateParams);
    } catch (e) {
      throw e;
    } finally {
      lswState.off();
    }
  };

  //DELETE CLIENT
  const deleteClient = async (clientId) => {
    try {
      lswState.on();
      await deleteClientAPI(clientId);
      loadClientList();
    } catch (e) {
      throw e;
    } finally {
      lswState.off();
    }
  };

  //ARCHIVE CLIENT
  const archiveClient = async (clientId) => {
    try {
      lswState.on();
      await archiveClientAPI(clientId);
      loadClientList();
    } catch (e) {
      throw e;
    } finally {
      lswState.off();
    }
  };

  //UNARCHIVE CLIENT
  const unarchiveClient = async (clientId) => {
    try {
      lswState.on();
      await unarchiveClientAPI(clientId);
      loadClientList();
    } catch (e) {
      throw e;
    } finally {
      lswState.off();
    }
  };

  const checkIsFilterApplied = () => {
    if (filterParams.isArchiveSelected || filterParams.selectedClientStatus != 0
      || filterParams.city || filterParams.email || filterParams.mobileNumber || filterParams.phoneNumber
    ) {
      return true;
    } else return false;
  };

  const isFilterApplied = checkIsFilterApplied();

  const mContext = {
    clientsList,
    pagination,
    loadClientList,
    checkIsFilterApplied,
    isFilterApplied,
    sfState,
    showFilter,
    clientLoading,
    filterParams,
    loadFilterOptions,
    resetClientListFilter,
    handleApplyFilter,
    filterOptions,
    createClient,
    loadClient,
    currentViewClient,
    updateClient,
    deleteClient,
    archiveClient,
    unarchiveClient,
    clientUsers,
    filtersLoading,
    loadingClientView,
    plantsList,
    clientPlantIds,
    plantLoading,
    logoAttachment,
    setLogoAttachment,
    loadPvPlantList,
    setLogoId,
    logoId,
    logoKey,
    updateShowListSize: (size) => setPagination({ ...pagination, size, page: 1 }),
    updateShowListPage: (page) => setPagination({ ...pagination, page }),
  };
  return <ClientManagementContext.Provider value={mContext}>{props.children}</ClientManagementContext.Provider>;
};
export const withClientManagementProvider = (Container, containerProps) => (props) =>
(
  <ClientManagementProvider>
    <Container {...containerProps} {...props} />
  </ClientManagementProvider>
);
