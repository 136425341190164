/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Row, Col, FormGroup, Form, Label } from "reactstrap";
import { Block, PreviewCard, Button, RSelect, Icon, BlockHeadContent } from "../../../components/Component";
import { UserManagementContext } from "../UserManagementProvider";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import * as yup from "yup";
import useBoolean from "../../../hooks/useBoolean";
import { useHistory } from "react-router-dom";
import { USER_MANAGEMENT_PAGE } from "../../../constants/routes";
import { RoleManagementContext } from "../../role_management/RoleManagementProvider";
import { errorToast, successToast } from "../../../components/toastify/Toastify";
import Attachments from "../../../components/attachments/Attachment";
import { phoneCode } from "../../../constants/country";
import { USER } from "../../../constants/PreferenceKeys";
import { AuthContext } from "../../../providers/AuthContext.provider";
import Head from "../../../layout/head/Head";
import Skeleton from "react-loading-skeleton";

const createSchema = yup
  .object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required(),
    password: yup.string().required().min(8, "Password must be 8 characters long"),
    status: yup.string().required(),
    resetPassword: yup.boolean(),
    phoneNumber: yup
      .number()
      .notRequired()
      .min(999999, "Mobile Number must be greater than 6")
      .max(9999999999999999, "Mobile Number must be less than 16")
      .default(),
    mobileNumber: yup
      .number()
      .notRequired()
      .min(999999, "Mobile Number must be greater than 6")
      .max(9999999999999999, "Mobile Number must be less than 16")
      .default(),
    phoneCode: yup.string().notRequired().default(`+44`),
    mobileCode: yup.string().notRequired().default(`+44`),

    category: yup.string().required("userType is required"),
    roleIds: yup.array().of(yup.number()).min(1).when("category", {
      is: "ADMIN",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired()
    }),
    clientId: yup.number().when("category", {
      is: "CLIENT",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired()
    }),
    plantIds: yup.array().of(yup.number()).min(1).when("category", {
      is: "CLIENT",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired()
    }),
  })
  .required();

const updateSchema = yup
  .object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required(),
    status: yup.string().required(),
    phoneNumber: yup
      .number()
      .notRequired()
      .min(999999, "Mobile Number must be greater than 6")
      .max(9999999999999999, "Mobile Number must be less than 16")
      .default(),
    mobileNumber: yup
      .number()
      .notRequired()
      .min(999999, "Mobile Number must be greater than 6")
      .max(9999999999999999, "Mobile Number must be less than 16")
      .default(),
    phoneCode: yup.string().default(`+44`),
    mobileCode: yup.string().default(`+44`),
    category: yup.string().required("userType is required"),
    roleIds: yup.array().of(yup.number()).min(1).when("category", {
      is: "ADMIN",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired()
    }),
    clientId: yup.number().when("category", {
      is: "CLIENT",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired()
    }),
    plantIds: yup.array().of(yup.number()).min(1).when("category", {
      is: "CLIENT",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired()
    }),
  })
  .required();

const UserForm = (props) => {
  const history = useHistory();

  const userManagementContext = useContext(UserManagementContext);
  const { currentViewUser, filterOptions, roleList, currentUserPlants,
    loadingUserView, userLoading
  } = userManagementContext;

  const { userRoles } = useContext(AuthContext);

  const { allRoles } = useContext(RoleManagementContext);

  const [isEditMode, updateEditMode] = useBoolean(false);
  const [passState, setPassState] = useState(false);

  const [userType, setUserType] = useState("")

  const userFormRef = useForm({
    resolver: yupResolver(props.userId ? updateSchema : createSchema),
  });

  const {
    setValue,
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = userFormRef;

  useEffect(() => {
    if (props.userId) {
      userManagementContext.loadUser(props.userId);
    }
    userManagementContext.loadFilterOptions();
  }, [props.userId]);

  useEffect(() => {
    if (currentViewUser) {
      updateEditMode.on();
      const { attachment, deletedAt, ...userData } = currentViewUser;
      setUserType(currentViewUser.category)
      if (currentViewUser.category === "ADMIN") {
        let roles = [];
        currentViewUser.userRoles.map((userRole) => {
          roles = [...roles, userRole.roleId];
        });

        reset({
          ...userData,
          roleIds: roles,
          phoneCode: userData.phoneNumber && userData.phoneNumber.split(" ")[0],
          phoneNumber: userData.phoneNumber && userData.phoneNumber.split(" ")[1],
          mobileCode: userData.mobileNumber && userData.mobileNumber.split(" ")[0],
          mobileNumber: userData.mobileNumber && userData.mobileNumber.split(" ")[1],
        });
      }
      else if (currentViewUser.category === "CLIENT") {
        let plantData = []
        if (currentUserPlants && currentUserPlants.length) {
          currentUserPlants.map((plant) => {
            plantData = [...plantData, plant.id];
          });
        }
        reset({
          ...userData,
          clientId: userData.clientUser && userData.clientUser[0].clientId,
          plantIds: plantData,
          phoneCode: userData.phoneNumber ? userData.phoneNumber.split(" ")[0] : "+44",
          phoneNumber: userData.phoneNumber && userData.phoneNumber.split(" ")[1],
          mobileCode: userData.mobileNumber ? userData.mobileNumber.split(" ")[0] : "+44",
          mobileNumber: userData.mobileNumber && userData.mobileNumber.split(" ")[1],
        });

      }
    }
  }, [currentViewUser, currentUserPlants]);

  const handleFormSubmit = (formData) => {
    if (isEditMode) {
      delete formData.userRoles;
      userManagementContext
        .updateUser(currentViewUser.id, {
          ...formData,
          email: formData.email.toLowerCase(),
          phoneNumber: formData.phoneNumber ? `${formData.phoneCode} ${formData.phoneNumber}` : "",
          mobileNumber: formData.mobileNumber ? `${formData.mobileCode} ${formData.mobileNumber}` : "",
        })
        .then((res) => {
          history.replace(USER_MANAGEMENT_PAGE);
          successToast({ description: "Successfully Updated" });
          if (props.onSuccessfulModal) props.onSuccessfulModal();
        })
        .catch((e) => {
          errorToast({ description: `${e}` });
        });
    } else {
      userManagementContext
        .createUser({
          ...formData,
          email: formData.email.toLowerCase(),
          phoneNumber: formData.phoneNumber ? `${formData.phoneCode} ${formData.phoneNumber}` : "",
          mobileNumber: formData.mobileNumber ? `${formData.mobileCode} ${formData.mobileNumber}` : "",
        })
        .then((res) => {
          history.replace(USER_MANAGEMENT_PAGE);
          successToast({ description: "Successfully Created" });
          if (props.onSuccessfulModal) props.onSuccessfulModal();
        })
        .catch((e) => {
          errorToast({ description: `${e}` });
        });
    }
  };

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  const renderCreateButton = () => {
    return (
      <>
        <Button disabled={loadingUserView} className={"mr-2"} color="primary" size="lg" isLoading={loadingUserView}
          onClick={loadingUserView ? null : handleSubmit(handleFormSubmit)}>
          Create User
        </Button>
        <Button size="lg" color="outline-primary" onClick={() => history.replace(USER_MANAGEMENT_PAGE)}>
          Cancel
        </Button>
      </>
    );
  };

  const renderEditButton = () => {
    return (
      <>
      <Button disabled={loadingUserView} className="mr-2"  color="primary" size="lg" isLoading={loadingUserView} onClick={
        loadingUserView ? null : handleSubmit(handleFormSubmit)}>
        Save Information
      </Button>
      <Button size="lg" color="outline-primary" onClick={() => history.replace(USER_MANAGEMENT_PAGE)}>
        Cancel
      </Button>
      </>
    )
  };

  const renderSaveButton = () => {
    if (isEditMode) {
      return renderEditButton();
    } else {
      return renderCreateButton();
    }
  };
  let roles = {};

  for (let i = 0; i < allRoles?.length; i++) {
    roles[allRoles[i].value] = allRoles[i].label;
  }
  return (
    <>
      <BlockHeadContent className="mb-1">
        <Head title={isEditMode ? "Brighter App | User | Edit" : "Brighter App | User | Create"} />
        <Link to={USER_MANAGEMENT_PAGE}>
          <Button color="light" outline className="bg-white d-none d-sm-inline-flex">
            <Icon name="arrow-left"></Icon>
            <span>Back</span>
          </Button>
        </Link>
      </BlockHeadContent>
      <Block size="lg">
        <PreviewCard>
          <div className="card-head">
            <h5 className="card-title">User Information</h5>
          </div>
          {userLoading ?
            <Skeleton count={10} className="w-100" />
            :
            <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
              <Row className="g-4">
                <Col lg="6">
                  <FormGroup>
                    <Label className="form-label" htmlFor="fv-full-name">
                      <span style={{ color: "indianred" }}>&#42;</span>First Name
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        {...register("firstName")}
                        disabled={currentViewUser && currentViewUser.status === "SUSPENDED"}
                        type="text"
                        name="firstName"
                        className="form-control"
                      />
                    </div>
                    {errors.firstName && (
                      <span className="invalid" style={{ color: "red" }}>
                        first name is required
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label className="form-label" htmlFor="fv-full-name">
                      <span style={{ color: "indianred" }}>&#42;</span>Last Name
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        {...register("lastName")}
                        disabled={currentViewUser && currentViewUser.status === "SUSPENDED"}
                        type="text"
                        name="lastName"
                        className="form-control"
                      />
                    </div>
                    {errors.lastName && (
                      <span className="invalid" style={{ color: "red" }}>
                        last name is required
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label className="form-label" htmlFor="fv-full-name">
                      <span style={{ color: "indianred" }}>&#42;</span>Email address
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        {...register("email")}
                        type="text"
                        name="email"
                        className="form-control"
                        autoComplete="new-password"
                        disabled={currentViewUser && currentViewUser.status === "SUSPENDED"}
                      />
                    </div>
                    {errors.email && (
                      <span className="invalid" style={{ color: "red" }}>
                        email is required
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="form-group">
                    <label className="form-label">Phone Number</label>
                    <div className="form-control-wrap d-flex">
                      <Col xs="4" md="2" lg="3" className="p-0">
                        <Controller
                          control={control}
                          name="phoneCode"
                          rules={{ required: true }}
                          render={({ field, ref }) => {
                            const options = phoneCode;
                            const selectedValue = options.find((e) => e.value === field.value);

                            return (
                              <RSelect
                                defaultValue={options[0]}
                                {...field}
                                ref={ref}
                                value={selectedValue}
                                options={options}
                                onChange={(o) => setValue("phoneCode", o.value)}
                                isDisabled={currentViewUser && currentViewUser.status === "SUSPENDED"}
                              />
                            );
                          }}
                        />
                      </Col>
                      <Col xs="8" md="10" lg="9" className="p-0">
                        <input
                          {...register("phoneNumber")}
                          disabled={currentViewUser && currentViewUser.status === "SUSPENDED"}
                          type="text"
                          name="phoneNumber"
                          className="form-control"
                        />
                      </Col>
                    </div>
                    {errors.phoneNumber && (
                      <span className="invalid" style={{ color: "red" }}>
                        phone number is required
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="form-group">
                    <label className="form-label">Mobile Number</label>
                    <div className="form-control-wrap d-flex">
                      <Col xs="4" md="2" lg="3" className="p-0">
                        <Controller
                          control={control}
                          name="mobileCode"
                          rules={{ required: true }}
                          render={({ field, ref }) => {
                            const options = phoneCode;
                            const selectedValue = options.find((e) => e.value === field.value);

                            return (
                              <RSelect
                                defaultValue={options[0]}
                                {...field}
                                ref={ref}
                                value={selectedValue}
                                options={options}
                                onChange={(o) => setValue("mobileCode", o.value)}
                                isDisabled={currentViewUser && currentViewUser.status === "SUSPENDED"}
                              />
                            );
                          }}
                        />
                      </Col>
                      <Col xs="8" md="10" lg="9" className="p-0">
                        <input
                          type="text"
                          name="mobileNumber"
                          disabled={currentViewUser && currentViewUser.status === "SUSPENDED"}
                          className="form-control"
                          {...register("mobileNumber")}
                        />
                      </Col>
                    </div>
                    {errors.mobileNumber && (
                      <span className="invalid" style={{ color: "red" }}>
                        mobile number is required
                      </span>
                    )}
                  </FormGroup>
                </Col>
                {!isEditMode && (
                  <Col lg="6">
                    <FormGroup className="form-group">
                      <label className="form-label">
                        <span style={{ color: "indianred" }}>&#42;</span>Password
                      </label>
                      <div className="form-control-wrap">
                        <a
                          href="#password"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setPassState(!passState);
                          }}
                          className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"
                            }`}
                        >
                          <Icon name="eye" className="passcode-icon icon-show"></Icon>
                          <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                        </a>
                        <input
                          type={passState ? "text" : "password"}
                          className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                          {...register("password")}
                          autoComplete="new-password"
                        />
                      </div>
                      {errors.password && (
                        <span className="invalid" style={{ color: "red" }}>
                          {errors.password?.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                )}
                {userRoles.includes("updateUser") && (
                  <Col lg="6" md="6">
                    <FormGroup className="form-group">
                      <label className="form-label">
                        <span style={{ color: "indianred" }}>&#42;</span>Status
                      </label>
                      <Controller
                        control={control}
                        name="status"
                        rules={{ required: true }}
                        render={({ field, ref }) => {
                          const options = filterOptions.userStatusses;
                          const selectedValue = options.find((e) => e.value === field.value);

                          return (
                            <RSelect
                              {...field}
                              ref={ref}
                              value={selectedValue}
                              options={options}
                              onChange={(o) => setValue("status", o.value, { shouldValidate: true })}
                            />
                          );
                        }}
                      />
                      {errors.status && (
                        <span className="invalid" style={{ color: "red" }}>
                          status is required
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                )}
                <Col lg="6" md="6">
                  <FormGroup className="form-group">
                    <label className="form-label" htmlFor="User">
                      <span style={{ color: "indianred" }}>&#42;</span>
                      Select the User Type
                    </label>
                    <Row className="p-1">
                      <Col md="6" lg="6">
                        <div className="custom-control custom-control-sm custom-radio">
                          <input
                            {...register("adminCategory")}
                            type="radio"
                            className="custom-control-input"
                            name="radio-list"
                            id="radio-admin"
                            onChange={() => {
                              setValue("category", "ADMIN")
                              setUserType("ADMIN")
                            }
                            }
                            defaultChecked={currentViewUser && currentViewUser.category === "ADMIN"}
                          />
                          <label className="custom-control-label" htmlFor="radio-admin">
                            Admin
                          </label>
                        </div>

                      </Col>
                      <Col md="6" lg="6">


                        <div className="custom-control custom-control-sm custom-radio">
                          <input
                            {...register("clientCategory")}
                            type="radio"
                            className="custom-control-input"
                            name="radio-list"
                            id="radio-client"
                            onChange={() => {
                              setValue("category", "CLIENT")
                              setUserType("CLIENT")
                            }}
                            defaultChecked={currentViewUser && currentViewUser.category === "CLIENT"}
                          />
                          <label className="custom-control-label" htmlFor="radio-client">
                            Client User
                          </label>
                        </div>

                      </Col>

                    </Row>
                  </FormGroup>
                </Col>
                <Col size="12">
                  <FormGroup>

                    <Controller
                      control={control}
                      rules={{ required: true }}
                      render={() => {
                        return (
                          <input
                            defaultChecked={isEditMode ? currentViewUser?.resetPassword : true}
                            name="resetPassword"
                            {...register("resetPassword")}
                            style={{ marginRight: 5 }}
                            type="checkbox"
                          />
                        );
                      }}
                    />
                    <label className="form-label">Reset password on user next login</label>
                  </FormGroup>
                </Col>
                {userType === "ADMIN" && <Col lg="6" md="6">

                  <FormGroup className="form-group">
                    <label className="form-label" htmlFor="roleIds">
                      <span style={{ color: "indianred" }}>&#42;</span>User Role
                    </label>
                    <Controller
                      control={control}
                      name="roleIds"
                      rules={{ required: true }}
                      render={({ field, ref }) => {
                        let options = roleList;
                        if (field.value && field.value.length) {
                          options = options.filter((e) => field.value.map((val) => e.val !== val));
                        } else {
                          options = roleList;
                        }
                        const selectedValue = options.filter((e) => field.value && field.value.includes(e.value));
                        return (
                          <RSelect
                            {...field}
                            ref={ref}
                            value={selectedValue}
                            isMulti
                            options={options}
                            isDisabled={currentViewUser && currentViewUser.status === "SUSPENDED"}
                            onChange={(o) => {
                              setValue(
                                "roleIds",
                                o.map((item) => item.value),
                                { shouldValidate: true }
                              );
                            }}
                          />
                        );
                      }}
                    />
                    {errors.roleIds && (
                      <span className="invalid" style={{ color: "red" }}>
                        role is required
                      </span>
                    )}
                  </FormGroup>

                </Col>
                }
                {userType === "CLIENT" && <Col lg="6" md="6">

                  <FormGroup className="form-group">
                    <label className="form-label" htmlFor="client">
                      <span style={{ color: "indianred" }}>&#42;</span>Client
                    </label>
                    <Controller
                      control={control}
                      name="clientId"
                      rules={{ required: true }}
                      render={({ field, ref }) => {
                        const options = filterOptions.clients
                        const selectedValue = options.find((e) => e.value === field.value);
                        return (
                          <RSelect
                            {...field}
                            ref={ref}
                            value={selectedValue}
                            options={options}
                            onChange={(o) => setValue("clientId", o.value, { shouldValidate: true })}
                          />
                        );
                      }}
                    />
                    {errors.clientId && (
                      <span className="invalid" style={{ color: "red" }}>
                        client is required
                      </span>
                    )}
                  </FormGroup>
                </Col>
                }
                {userType === "CLIENT" && <Col lg="6" md="6">
                  <FormGroup className="form-group">
                    <label className="form-label" htmlFor="plantIds">
                      <span style={{ color: "indianred" }}>&#42;</span>Plant
                    </label>
                    <Controller
                      control={control}
                      name="plantIds"
                      rules={{ required: true }}
                      render={({ field, ref }) => {
                        let options = filterOptions.plants;
                        if (field.value && field.value.length) {
                          options = options.filter((e) => field.value.map((val) => e.val !== val));
                        } else {
                          options = filterOptions.plants;
                        }
                        const selectedValue = options.filter((e) => field.value && field.value.includes(e.value));
                        return (
                          <RSelect
                            {...field}
                            ref={ref}
                            value={selectedValue}
                            isMulti
                            options={options}
                            // isDisabled={currentViewUser && currentViewUser.status === "SUSPENDED"}
                            onChange={(o) => {
                              setValue(
                                "plantIds",
                                o.map((item) => item.value),
                                { shouldValidate: true }
                              );
                            }}
                          />
                        );
                      }}
                    />
                    {errors.plantIds && (
                      <span className="invalid" style={{ color: "red" }}>
                        plant is required
                      </span>
                    )}
                  </FormGroup>

                </Col>
                }
                {isEditMode && currentViewUser && currentViewUser.category === "ADMIN" && (
                  <Col lg="12">
                    <FormGroup className="form-group">
                      <div className="form-control-wrap">
                        <Attachments
                          module={USER}
                          id={currentViewUser.id}
                          attachments={currentViewUser.attachment}
                          subModule={""}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                )}
                <Col xl="12" className="d-flex justify-content-end">
                  {renderSaveButton()}
                </Col>
              </Row>
            </Form>}
        </PreviewCard>
      </Block>
    </>
  );
};

export default UserForm;
