/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import useBoolean from "../../hooks/useBoolean";
import {
  archiveUserAPI,
  createUserAPI,
  getAdminUserList,
  getAdminUserListFilterOptions,
  getUserAPI,
  unarchiveUserAPI,
  updateUserAPI,
  updateUserStatusAPI,
  deleteUserAPI,
  updateUserPassword,
  getAllRoles,
} from "./UserManagementRepository";

export const UserManagementContext = React.createContext();

const initialPaginationState = {
  page: 1,
  size: 50,
};

const initialFilterOptions = {
  userRoles: [],
  userStatusses: [],
  categories: [],
  clients: [],
  plants: []
};

const initialFilterParams = {
  selectedUserRole: [],
  selectedUserStatus: [],
  selectedUserCategory: [],
  isArchiveSelected: false,
  searchText: "",
  sortingField: "firstName",
  sortingOrder: "ASC"
};

export const UserManagementProvider = (props) => {
  const [usersList, setUsersList] = React.useState([]);
  const [roleList, setRoleList] = React.useState([]);

  const [pagination, setPagination] = React.useState(Object.assign({}, initialPaginationState));
  const [filterOptions, setFilterOptions] = React.useState(Object.assign({}, initialFilterOptions));
  const [filterParams, setFilterParams] = React.useState(Object.assign({}, initialFilterParams));

  const [currentViewUser, setCurrentViewUser] = React.useState(null);
  const [currentUserClient, setCurrentUserClient] = React.useState(null);
  const [currentUserPlants, setCurrentUserPlants] = React.useState(null);

  // LOADERS
  const [userLoading, ulState] = useBoolean(false);
  const [filtersLoading, flState] = useBoolean(false);
  const [inlineLoading, ilState] = useBoolean(false);
  const [loadingUserView, lswState] = useBoolean(false);
  const [loadingRoles, rlState] = useBoolean(false);

  const [showFilter, sfState] = useBoolean(false);

  const loadFilterOptions = async () => {
    flState.on();

    try {
      const response = await getAdminUserListFilterOptions();
      setFilterOptions(response);
    } catch (e) {
      // HANDLE ERROR
    } finally {
      flState.off();
    }
  };

  const getUserListFilterParams = () => {
    const queryParams = { ...pagination };
    const { selectedUserRole, selectedUserStatus, isArchiveSelected,
      searchText, selectedUserCategory, sortingField, sortingOrder } = filterParams;
    if (selectedUserStatus) queryParams["userSearchText"] = searchText;
    if (selectedUserRole) queryParams["userRole"] = selectedUserRole;
    if (selectedUserStatus) queryParams["userStatus"] = selectedUserStatus;
    if (isArchiveSelected != null) queryParams["userIsArchived"] = isArchiveSelected;
    if (selectedUserCategory) queryParams["userCategory"] = selectedUserCategory;
    if (sortingField) queryParams["sortingField"] = sortingField;
    if (sortingOrder) queryParams["sortingOrder"] = sortingOrder;
    return queryParams;
  };

  const loadUserList = async () => {
    ulState.on();

    try {
      const filterParams = getUserListFilterParams();
      const response = await getAdminUserList(filterParams);
      setUsersList(response.userList);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      ulState.off();
    }
  };

  const updatePagination = () => { };

  const handleApplyFilter = (selectedFilters) => {
    setFilterParams(selectedFilters);
  };
  const checkIsFilterApplied = () => {
    if (filterParams.selectedUserRole.length != 0 || filterParams.selectedUserStatus != 0) {
      return true;
    } else return false;
  };
  const isFilterApplied = checkIsFilterApplied();

  const resetUserListFilter = () => setFilterParams(Object.assign({}, initialFilterParams));

  const saveUserListFilter = () => { };

  const archiveUser = async (userId) => {
    ilState.on();
    try {
      await archiveUserAPI(userId);
      await loadUserList();
    } catch (e) {
      throw e;
    } finally {
      ilState.off();
    }
  };

  const unarchiveUser = async (userId) => {
    ilState.on();
    try {
      await unarchiveUserAPI(userId);
      await loadUserList();
    } catch (e) {
      throw e;
    } finally {
      ilState.off();
    }
  };

  const suspendUser = async (userId) => {
    ilState.on();
    try {
      await updateUserStatusAPI(userId, { status: "SUSPENDED" });
      await loadUserList();
    } catch (e) {
      throw e;
    } finally {
      ilState.off();
    }
  };

  const userPassword = async (userId, params) => {
    ilState.on();
    try {
      await updateUserPassword(userId, { password: params.password });
    } catch (e) {
      throw e;
    } finally {
      ilState.off();
    }
  };
  ///// VIEW USER
  const loadUser = async (userId) => {
    ulState.on();
    try {
      const response = await getUserAPI(userId);
      setCurrentViewUser(response.user);
      setCurrentUserClient(response.client)
      setCurrentUserPlants(response.plants)
    } catch (e) {
      throw e;
    } finally {
      ulState.off();
    }
  };

  const updateUser = async (userId, updateParams) => {
    try {
      lswState.on();
      await updateUserAPI(userId, updateParams);
    } catch (e) {
      throw e;
    } finally {
      lswState.off();
    }
  };

  const createUser = async (createParams) => {
    try {
      lswState.on();
      await createUserAPI(createParams);
    } catch (e) {
      throw e;
    } finally {
      lswState.off();
    }
  };

  //DELETE USER
  const deleteUser = async (plantId) => {
    try {
      lswState.on();
      await deleteUserAPI(plantId);
      loadUserList();
    } catch (e) {
      throw e;
    } finally {
      lswState.off();
    }
  };

  const loadAllRoles = async () => {
    rlState.on();
    try {
      const response = await getAllRoles();
      setRoleList(response);
    } catch (e) {
      // HANDLE ERROR
    } finally {
      rlState.off();
    }
  };

  const mContext = {
    usersList,
    isFilterApplied,
    sfState,
    showFilter,
    checkIsFilterApplied,
    pagination,
    loadUserList,
    updatePagination,
    userLoading,
    filterOptions,
    filtersLoading,
    filterParams,
    handleApplyFilter,
    resetUserListFilter,
    saveUserListFilter,
    archiveUser,
    unarchiveUser,
    suspendUser,
    inlineLoading,
    loadFilterOptions,
    userPassword,
    loadUser,
    currentViewUser,
    updateUser,
    createUser,
    deleteUser,
    loadingUserView,
    roleList,
    loadAllRoles,
    loadingRoles,
    currentUserClient,
    currentUserPlants,
    updateShowListSize: (size) => setPagination({ ...pagination, size, page: 1 }),
    updateShowListPage: (page) => setPagination({ ...pagination, page }),
  };

  return <UserManagementContext.Provider value={mContext}>{props.children}</UserManagementContext.Provider>;
};

export const withUserManagementProvider = (Container, containerProps) => (props) =>
(
  <UserManagementProvider>
    <Container {...containerProps} />
  </UserManagementProvider>
);
