import { format } from "date-fns";
import React, { useContext, useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  Icon,
  PaginationComponent,
  Sidebar,
} from "../../../../../components/Component";
import FilteredByList from "../../../../../components/FilteredBy";
import Check from "../../../../../components/check_roles/check";
import { confirmationSweetAlert } from "../../../../../components/sweetAlert/SweetAlert";
import { errorToast, successToast } from "../../../../../components/toastify/Toastify";
import { PREVENTATIVE_TASK_FILTERS } from "../../../../../constants/PreferenceKeys";
import { PREVENTATIVE_GENERATOR_PAGE } from "../../../../../constants/routes";
import ModalViewer from "../../../ModalViewer";
import { MODE, MONTH_OPTIONS, STATUS } from "../../constants";
import { PreventativeTaskContext } from "../PreventativeTaskProvider";
import { deletePreventativeTask } from "../PreventativeTaskRepository";
import ExportForm, { TASK_FIELDS } from "./ExportForm";
import PreventativeListFilter from "./PreventativeListFilter";
import PreventativeTaskForm from "./PreventativeTaskForm";
import { updateViewSettings } from "../../../../../repositories/ViewRepository";
import { createPortal } from "react-dom";
import { createColumnHelper } from "@tanstack/react-table";
import TanstackTable from "../../../../../components/tableV1/Table";
import SortableColumns from "../../../../../components/tableV1/SortableColumns";
import { STATUS_OPTION, statusColorTheme, taskCategory } from "../../../../../constants/constants";
import useBoolean from "../../../../../hooks/useBoolean";
import { MaintenanceContext } from "../../../../../providers/Maintenance.provider";
import {  openImageInNewTab } from "../../../../../utils/Utils";
import ToolTip from "../../../../../components/ToolTip";
import AttachmentList from "../../../../../components/FileAttachment";

const columnHelper = createColumnHelper();

const PreventiveTaskList = () => {
  const preventativeContext = useContext(PreventativeTaskContext);
  const {
    pagination,
    filterParams,
    preventativeTasks,
    plants,
    engineers,
    preventativeTaskLoading,
    handleApplyFilter,
    loadPreventativeTasks,
    showFilter,
    sfState,
    isFilterApplied,
    archiveTask,
    unarchiveTask,
    tasksColumnSettings,
    setTasksColumnSettings, getTableSettings,
  } = preventativeContext;
  const { userList } = useContext(MaintenanceContext)
  const [sm, updateSm] = useState(false);
  const [mode, setMode] = useState("");
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [currentTaskId, setCurrentTaskId] = useState("");
  const [exportLoading] = useState(false);
  const [isColumnArrangeModalOpen, icamState] = useBoolean(false);
  const [selectedAttachment, setSelectedAttachment] = useState({});
  const [isAttachmentModalOpen, iamoState] = useBoolean(false);

  const renderTableValue = (tableValue) => {
    if (!tableValue) {
      return <div>-</div>;
    }
    return <div data-toggle="tooltip" style={{cursor: "pointer"}} title={tableValue}>{tableValue} </div>;
  };
  const getPlantName = (plantId) => {
    const plantData = plants.find((p) => p.value === plantId);
    return plantData?.label || "";
  };
  const getEngineerName = (plantId) => {
    const engineer = engineers.find((p) => p.value === plantId);
    return engineer?.label || "";
  };

  function tableColumn(columnSetting) {
    switch (columnSetting.field_key) {
      case "title":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => "Ticket Name",
          cell: (info) => {
            return (
              <div style={{ display: "flex", gap: "10px", cursor: "pointer", }}
                onClick={() => {
                  viewTask(info.row.original.id);

                }}>
                <p style={{ fontWeight: "bold", color: "#364a63" }}>{renderTableValue(info.getValue())}</p>
              </div>
            );
          },
        });
      case "status":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(
            <Badge className="text-capitalize" style={statusColorTheme[info.row.original?.status]} pill>
              <span>{STATUS_OPTION[info.row.original?.status] || "-"}</span>
            </Badge>
          )
        });
      case "identifier":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(info.getValue()),
        });
      case "startedAt":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (info.row.original?.startedAt) {
              return `${format(new Date(info.row.original?.startedAt || null), "dd/MM/yyyy")}`
            }
            else {
              return "-"
            }
          }
        });

      case "resolvedAt":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (info.row.original?.resolvedAt) {
              return `${format(new Date(info.row.original?.resolvedAt || null), "dd/MM/yyyy")}`
            }
            else {
              return "-"
            }
          }
        });
      case "plantId":
        return columnHelper.accessor("plants", {
          id: "plantId",
          enableResizing: true,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(info.row.original.plants?.name),
        });
      case "isArchived":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => <p>{info.getValue() ? "Archived" : "UnArchived"}</p>,
        });
      case "priority":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(info.getValue()),
        });
      case "code":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(info.getValue()),
        });
      case "frequency":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(info.getValue()),
        });
      case "year":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(info.getValue()),
        });
      case "months":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (!info.row.original?.months?.length) {
              return <p>-</p>;
            }
            const monthList = MONTH_OPTIONS.filter((item) => info.row.original?.months.includes(item.value));
            return (
              <div style={{ display: "flex" }}>
                {monthList.map((m, i) => {
                  return (
                    <div key={`month-${i}`}>
                      {m.label}
                      {i !== monthList.length - 1 && <span>,&nbsp;</span>}
                    </div>
                  );
                })}
              </div>
            );
          },
        });
      case "description":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => <ToolTip id={`id-${info.row.original?.id}-description`} value={info.getValue()}/>
        });
      case "comment":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => <ToolTip id={`id-${info.row.original?.id}-comment`} value={info.getValue()}/>
        });

      case "category":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (info.row.original?.category) {
              return `${taskCategory(info.row.original.category)}`
            }
            else {
              return "-"
            }
          }

        });
      case "assetCategoryId":
        return columnHelper.accessor("assetCategory", {
          id: "assetCategoryId",
          header: () => "Asset Category",
          cell: (info) => renderTableValue(info.row.original?.assetCategory?.name),
        });
      case "createdById":
        return columnHelper.accessor("createdBy", {
          id: "createdById",
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (info.row.original?.createdBy) {
              return (
                <p>
                  {info.row.original?.createdBy?.firstName}
                  {info.row.original?.createdBy?.lastName}
                </p>
              );
            }
            else {
              return "-";
            }
          },
        });
      case "parentId":
        return columnHelper.accessor("parentId", {
          id: "parentId",
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (info.row.original?.createdBy) {
              return (
                <p>
                  {info.row.original?.createdBy?.firstName}
                  {info.row.original?.createdBy?.lastName}
                </p>
              );
            }
            else {
              return "-";
            }

          },
        });
      case "fieldEngineerIds":
        return columnHelper.accessor("fieldEngineerIds", {
          id: columnSetting.field_key,
          enableSorting: false,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (!info.row.original?.fieldEngineerIds?.length) {
              return <p>-</p>;
            }
            const user = userList.filter((item) => info.row.original?.fieldEngineerIds.includes(item.value));
            return (
              <div style={{ display: "flex" }}>
                {user.map((u, i) => {
                  return (
                    <div key={`field-engineer-${i}`}>
                      {u.label}
                      {i !== user.length - 1 && <span>,&nbsp;</span>}
                    </div>
                  );
                })}
              </div>
            );
          },
        });
      case "assignedToIds":
        return columnHelper.accessor("assignedToIds", {
          id: columnSetting.field_key,
          enableSorting: false,
          enableResizing: true,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (!info.row.original?.assignedToIds?.length) {
              return <p>-</p>;
            }
            return (
              <div style={{ display: "flex" }} className="flex-wrap">
                {info.row.original?.assignedToIds
                  ?.map((id, key) => {
                    return getEngineerName(id);
                  })
                  .join(", ") || "-"}

              </div>
            )
          },
        });
      case "attachment":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          enableSorting: false,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => <AttachmentList
            attachments={info.row.original?.attachment}
            taskName={info.row.original?.title}
            openImageInNewTab={openImageInNewTab}
            setSelectedAttachment={setSelectedAttachment}
            iamoState={iamoState}
          />
        });
      default:
        return columnHelper.accessor(columnSetting.field_key, {
          id: "id",
          enableSorting: false,
          header: () => columnSetting.field_key ?? "",
          cell: (info) => {
            return <p>unknown column</p>;
          },
        });
    }
  }

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const [isExpotModalOpen, setIsExportModalOpen] = useState(false);

  const toggle = () => setonSearch(!onSearch);

  const focusSearchRef = useRef();

  useEffect(() => {
    if (focusSearchRef.current) focusSearchRef.current.focus();
  });

  const onSuccessfulModal = () => {
    loadPreventativeTasks({
      ...tasksColumnSettings,
      filters: { ...tasksColumnSettings.filters },
    });;
  };
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };
  const onSearchAction = () => {
    handleApplyFilter({ ...filterParams, taskText: onSearchText });
    loadPreventativeTasks({
      ...tasksColumnSettings,
      filters: { ...tasksColumnSettings.filters, taskText: onSearchText },
    });
  };

  useEffect(() => {
    const filters = localStorage.getItem(PREVENTATIVE_TASK_FILTERS);
    if (filters) {
      const parseFilters = JSON.parse(filters);
      handleApplyFilter({
        ...parseFilters,
      });
    }
  }, []);

  const currentItems = preventativeTasks?.items || [];

  const handleArchive = async (taskId) => {
    archiveTask(taskId)
      .then((res) => {
        successToast({ description: " Preventative Task Archived successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while archive Preventative Task" });
      });
  };
  const handleUnArchive = async (taskId) => {
    unarchiveTask(taskId)
      .then((res) => {
        successToast({ description: "Preventative Task UnArchived successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while unarchive Preventative Task" });
      });
  };

  const viewTask = (id) => {
    setMode(MODE.VIEW);
    setIsOpen(true);
    setCurrentTaskId(id);
  };
  const editTask = (id) => {
    setMode(MODE.EDIT);
    setIsOpen(true);
    setCurrentTaskId(id);
  };
  const deleteFunc = async (taskId) => {
    try {
      await deletePreventativeTask(taskId);
      onSuccessfulModal();
      setIsOpen(false);
      successToast({ description: "Successfully Deleted" });
    } catch (err) {
      errorToast({ description: "Error happened while deleting Preventative Task" });
    }
  };

  async function handleUpdateViewSettings(payload) {
    return await updateViewSettings(payload);
  }

  const handleNameSort = (sortOrder, field) => {
    handleApplyFilter({ ...filterParams, sortingOrder: sortOrder, sortingField: field });
    const updatedSettings = {
      ...tasksColumnSettings,
      filters: { ...tasksColumnSettings.filters, sortingField: field, sortingOrder: sortOrder },
    };
    handleUpdateViewSettings(updatedSettings);
    loadPreventativeTasks(updatedSettings);
    setTasksColumnSettings(updatedSettings);
  };


  const handleDeleteConfirmation = (taskId) => {
    confirmationSweetAlert({
      id: taskId,
      handleConfirmation: deleteFunc,
    });
  };
  const getComponent = () => {
    return (
      <PreventativeTaskForm
        mode={mode}
        setMode={setMode}
        currentTaskId={currentTaskId}
        handleDeleteConfirmation={handleDeleteConfirmation}
        setIsOpen={setIsOpen}
      />
    );
  };
  const toggleExportModal = () => {
    setIsExportModalOpen(!isExpotModalOpen);
  };
  const getExportComponent = () => {
    return <ExportForm onSuccessfulModal={onSuccessfulModal} filterParams={filterParams} />;
  };
  const createExport = () => {
    setIsExportModalOpen(!isExpotModalOpen);
    return <>Edit</>;
  };

  const assignedEngineerSelectedData = filterParams.selectedAssignedEngineer && filterParams.selectedAssignedEngineer.map((item, key) => {
    return engineers?.[engineers?.findIndex((status) => status.value === item)];
  });

  const monthSelectedData = filterParams.selectedMonthFilter && filterParams.selectedMonthFilter.map((item, key) => {
    return MONTH_OPTIONS?.[MONTH_OPTIONS?.findIndex((status) => status.value === item)];
  });

  const plantSelectedData = filterParams.selectedPlant && filterParams.selectedPlant.map((item, key) => {
    return plants?.[plants?.findIndex((status) => status.value === item)];
  });
  const selectYear = filterParams.taskYear?.split("/")[0];

  const columns = React.useMemo(() => {
    if (tasksColumnSettings?.fields?.length) {
      return tasksColumnSettings.fields
        .filter((cs) => cs.isChecked)
        .map((columnSetting) => {
          return tableColumn(columnSetting);
        });
    }
    return [];
  }, [preventativeTasks.items]);

  const renderAction = React.useMemo(() => {
    return [
      columnHelper.accessor("id", {
        header: "",
        size: 40,
        enableSorting: false,
        enableResizing: false,
        cell: (info) => {
          const item = info?.row.original;
          return (
            <ul className="nk-tb-actions gx-1">
              <UncontrolledDropdown>
                <DropdownToggle id="menu-btn-preventative" tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                  <Icon name="more-h"></Icon>
                </DropdownToggle>
                {createPortal(
                  <DropdownMenu right>
                    <ul className="link-list-opt no-bdr">
                      <React.Fragment>
                        <Check permission={["manageTickets"]}>
                          {(item?.plants?.status && item.plants.status === "ACTIVE")
                            &&
                            <>
                              <li /* className="nk-tb-action-hidden" */ onClick={() => editTask(item.id)}>
                                <DropdownItem
                                  id="edit-preventative-btn"
                                  tag="a"
                                  href="#edit"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                  }}
                                >
                                  <Icon name="edit-alt-fill"></Icon>
                                  <span>Edit Task</span>
                                </DropdownItem>
                              </li>
                              <li className="divider"></li>
                            </>
                          }
                          {item.isArchived ? (
                            <React.Fragment>
                              <li onClick={() => handleUnArchive(item.id)}>
                                <DropdownItem
                                  id="unarchive-preventative"
                                  tag="a"
                                  href="#unarchive"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                  }}
                                >
                                  <Icon name="unarchive"></Icon>
                                  <span>UnArchive Task</span>
                                </DropdownItem>
                              </li>
                              <li className="divider"></li>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <li onClick={() => handleArchive(item.id)}>
                                <DropdownItem
                                  id="archive-preventative"
                                  tag="a"
                                  href="#archive"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                  }}
                                >
                                  <Icon name="archive"></Icon>
                                  <span>Archive Task</span>
                                </DropdownItem>
                              </li>
                            </React.Fragment>
                          )}
                        </Check>
                        <Check permission={["manageTickets"]}>
                          {item.status !== "SUSPENDED" && (
                            <>
                              <li className="divider"></li>
                              <li onClick={() => handleDeleteConfirmation(item.id)}>
                                <DropdownItem
                                  id="delete-preventative"
                                  tag="a"
                                  href="#delete"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                  }}
                                >
                                  <Icon name="trash"></Icon>
                                  <span>Delete Task</span>
                                </DropdownItem>
                              </li>
                            </>
                          )}
                        </Check>
                      </React.Fragment>
                    </ul>
                  </DropdownMenu>,
                  document.body
                )}
              </UncontrolledDropdown>

            </ul>
          );
        },
      }),
    ];
  }, [preventativeTasks.items]);

  const renderTable = React.useMemo(() => {
    return (
      <TanstackTable
        dataSource={preventativeTasks.items ?? []}
        _column={[...columns, ...renderAction]}
        columnSettings={tasksColumnSettings}
        onColumWidthChanged={(resizedColumn) => {
          const _tasksColumnSettings = [...tasksColumnSettings.fields].map((ts) => {
            if (ts.field_key === resizedColumn.field_key) {

              return {
                ...ts,
                width: resizedColumn.width,
              };
            } else {
              return ts;
            }
          });
          handleUpdateViewSettings({
            entityType: "PREVENTATIVE_MAINTENANCE",
            fields: _tasksColumnSettings,
          });
          setTasksColumnSettings((prev) => ({ ...prev, fields: _tasksColumnSettings }));
        }}
        handleSorting={(sortingParams) => {
          handleNameSort(sortingParams.sortingOrder, sortingParams.sortingField);
        }}
      />
    );
  }, [columns, preventativeTasks.items, tasksColumnSettings]);

  return (
    <React.Fragment>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h3" page>
              Preventative Maintenance Ticket
            </BlockTitle>
            <BlockDes className="text-soft">
              <p>You have a total of {preventativeTasks?.pagination?.totalCount || 0} preventative tickets.</p>
            </BlockDes>
            <div className="d-flex flex-wrap">
              {filterParams.selectedTaskStatus && (
                <FilteredByList
                  data={filterParams.selectedTaskStatus}
                  title={"Status"}
                  isNotMap={true}
                  isStr={true}
                />
              )}
              {assignedEngineerSelectedData.length ? (
                <FilteredByList
                  data={assignedEngineerSelectedData}
                  title={"Assigned Engineer"}
                  isMap={true}
                  isStr={false}
                />
              ) : null}
              {plantSelectedData.length ? (
                <FilteredByList
                  data={plantSelectedData}
                  title={"Plant"}
                  isMap={true}
                  isStr={false}
                />
              ) : null}
              {/* {filterParams.startDate && <FilteredByList data={new Date(filterParams.startDate)} title={"Start Date"} isNotMap={true}
                isStr={true} />} */}
              {monthSelectedData.length ? <FilteredByList data={monthSelectedData?.label} title={"Month"} isNotMap={true}
                isStr={true} /> : null}
              {selectYear && <FilteredByList data={selectYear} title={"Year"} isNotMap={true}
                isStr={true} />}

              {/* {filterParams.resolvedDate && <FilteredByList data={filterParams.resolvedDate} title={"Resolved Date"} isNotMap={true}
                isStr={true} />} */}
              {filterParams.taskText && <FilteredByList data={filterParams.taskText} title={"Searched by"} isNotMap={true} isStr={true} />}
            </div>
          </BlockHeadContent>
          <BlockHeadContent>
            <div className="toggle-wrap nk-block-tools-toggle">
              <Button
                className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                onClick={() => updateSm(!sm)}
              >
                <Icon name="menu-alt-r"></Icon>
              </Button>
              <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                <ul className="nk-block-tools g-3 d-inline-block">
                  <li>
                    <Button
                      disabled={preventativeTasks?.pagination?.totalCount === 0}
                      className="btn btn-white btn-outline-light"
                      type="export"
                      isLoading={exportLoading}
                      onClick={createExport}
                    >
                      <Icon name="download-cloud"> </Icon>
                      <span>Export</span>
                    </Button>
                  </li>
                  <Check permission={["manageTickets"]}>
                    <li>
                      <a
                        href={PREVENTATIVE_GENERATOR_PAGE}
                        // onClick={(ev) => {
                        //     ev.preventDefault();
                        // }}
                        className="btn btn-primary "
                      >
                        <Icon name="plus"></Icon>
                        <span>Generate Preventative Task</span>
                      </a>
                    </li>
                  </Check>
                </ul>
              </div>
            </div>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      <Block>
        <DataTable className="card-stretch">
          <div className="card-inner position-relative card-tools-toggle ">
            <div className="card-title-group ">
              <div className="card-tools"></div>
              <div className="card-tools mr-n1 ">
                <ul className="btn-toolbar gx-1">
                  <li>
                    <a
                      href="#search"
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggle();
                      }}
                      className="btn btn-icon search-toggle toggle-search"
                    >
                      <Icon name="search"></Icon>
                    </a>
                  </li>
                  <li className="btn-toolbar-sep"></li>
                  <div>
                    <Sidebar toggleState={showFilter}>
                      <div>
                        <PreventativeListFilter isPlantName={false} plants={plants} engineers={engineers} />
                      </div>
                    </Sidebar>
                    <div tag="a" className="btn btn-icon " onClick={() => sfState.toggle()}>
                      {isFilterApplied ? <div className="dot dot-primary"></div> : null}
                      <Icon name="filter-alt"></Icon>
                    </div>
                    {(preventativeTasks?.pagination?.totalCount > 0) &&
                      <div id="task-filter" tag="a" className="btn btn-icon " onClick={() => icamState.on()}>
                        <Icon name="view-list-fill"></Icon>
                      </div>
                    }
                    <ModalViewer
                      title={`${selectedAttachment.taskName}'s Attachments`}
                      isOpen={isAttachmentModalOpen}
                      size="md"
                      toggleModal={iamoState.toggle}
                      component={
                        Object.keys(selectedAttachment).length ? (
                          <div>
                            {selectedAttachment?.attachments.map((attachment) => {
                              return (
                                <div style={{ display: "flex", alignItems: "center" }}>
                                  <em class="icon ni ni-dot"></em>
                                  <p
                                    style={{ cursor: "pointer" }}
                                    onClick={() => openImageInNewTab(attachment?.files.key)}
                                  >
                                   <div style={{ color: "#0000EE", cursor: "pointer"}} href="">{attachment?.files?.name}</div> 
                                   </p>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <></>
                        )
                      }
                    />
                    <ModalViewer
                      title="Preventative Task Columns"
                      isOpen={isColumnArrangeModalOpen}
                      size="md"
                      toggleModal={icamState.toggle}
                      component={
                        <SortableColumns
                          entityType={"PREVENTATIVE_MAINTENANCE"}
                          fields={TASK_FIELDS}
                          updateViewSettings={async (modifiedSettings) => {
                            const updatedSettings = await handleUpdateViewSettings({
                              ...tasksColumnSettings,
                              fields: modifiedSettings,
                            });
                            setTasksColumnSettings((prev) => ({ ...prev, fields: updatedSettings.fields }));
                            await loadPreventativeTasks(updatedSettings);
                          }}
                          closeModal={icamState.off}
                          visibleColumns={tasksColumnSettings.fields}
                        />
                      }
                    />
                  </div>
                </ul>
              </div>
            </div>
            <div className={`card-search search-wrap ${!onSearch && "active"}`}>
              <div className="card-body">
                <div className="search-content">
                  <Button
                    className="search-back btn-icon toggle-search active"
                    onClick={() => {
                      setSearchText("");
                      handleApplyFilter({ ...filterParams, taskText: "" });
                      toggle();
                    }}
                  >
                    <Icon name="arrow-left"></Icon>
                  </Button>
                  <input
                    type="text"
                    className="border-transparent form-focus-none form-control"
                    placeholder="Search by Ticket Name,status and Plant Name.enter to search"
                    ref={(el) => {
                      focusSearchRef.current = !onSearch ? el : null;
                    }}
                    value={onSearchText}
                    onChange={(e) => onFilterChange(e)}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        onSearchAction();
                      }
                      if (e.keyCode === 27) {
                        setSearchText("");
                        handleApplyFilter({ ...filterParams, searchText: "" });
                        toggle();
                      }
                      if (e.keyCode === 88) {
                        setSearchText("");
                      }
                    }}
                  />
                  <Button className="search-submit btn-icon">
                    <Icon name="search" onClick={onSearchAction}></Icon>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {preventativeTaskLoading || preventativeContext.isLoadingFilters ? <Skeleton count={10} className="w-100" /> :
            (preventativeTasks?.pagination?.totalCount > 0) && renderTable}
          <div className="card-inner">
            {preventativeTasks.pagination && preventativeTasks.pagination.totalCount > 0 ? (
              <PaginationComponent
                itemPerPage={pagination.size}
                totalItems={preventativeTasks.pagination.totalCount}
                paginate={(page) => {
                  preventativeContext.loadPreventativeTasks({
                    ...tasksColumnSettings,
                    filters: { ...tasksColumnSettings.filters, page },
                  });
                  preventativeContext.updateShowListPage(page);
                }}
                paginatePage={(size) => {
                  preventativeContext.updateShowListSize(size);
                  const updatedSetting = {
                    ...tasksColumnSettings,
                    filters: { ...tasksColumnSettings.filters, size },
                  };
                  handleUpdateViewSettings(updatedSetting);
                  setTasksColumnSettings(updatedSetting);
                  loadPreventativeTasks(updatedSetting);
                }}
                currentPage={preventativeTasks.pagination.currentPage}
              />
            ) : 
              (!preventativeTaskLoading && preventativeTasks?.pagination?.totalCount === 0 ) &&(
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )
             }
          </div>
        </DataTable>
      </Block>
      <ModalViewer title="Preventative Task" isOpen={isOpen} toggleModal={toggleModal} component={getComponent()} />
      <ModalViewer
        title=" Preventative Maintenance - Select the Export Fields"
        isOpen={isExpotModalOpen}
        size="md"
        toggleModal={toggleExportModal}
        component={getExportComponent()}
      />
    </React.Fragment>
  );
};
export default PreventiveTaskList;
