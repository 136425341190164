import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  Sidebar,
} from "../../../../components/Component";
import FilteredByList from "../../../../components/FilteredBy";
import Check from "../../../../components/check_roles/check";
import { confirmationSweetAlert } from "../../../../components/sweetAlert/SweetAlert";
import { errorToast, successToast } from "../../../../components/toastify/Toastify";
import ModalViewer from "../../ModalViewer";
import { SlaContext } from "../SlaProvider";
import SlaFormContainer from "../containers/SlaFormContainer";
import SlaViewContainer from "../containers/SlaViewContainer";
import ExportForm from "./ExportForm";
import SlaListFilter from "./SlaListFilter";

const SlaListCompact = () => {
  // REFS
  const focusSearchRef = React.useRef();

  const slaContext = useContext(SlaContext);
  const { slaList, pagination } = slaContext;
  const { loadSlaList, deleteSla, slaLoading, filterParams, handleApplyFilter, isFilterApplied, sfState, showFilter } =
    slaContext;
  const [sm, updateSm] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [sla, setSla] = useState("");
  const [view, setView] = useState("");
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const [isExpotModalOpen, setIsExportModalOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const VIEW = {
    VIEW: "VIEW",
    CREATE: "CREATE",
    EDIT: "EDIT",
  };

  useEffect(() => {
    if (focusSearchRef.current) focusSearchRef.current.focus();
  });

  // Changing state value when searching name
  useEffect(() => {}, [onSearchText]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  const handleDeleteSla = async (slaId) => {
    deleteSla(slaId)
      .then((res) => {
        successToast({ description: "SLA is deleted successfully" });
      })
      .catch((e) => {
        errorToast({ description: `${e}` });
      });
  };
  const handleDeleteConfirmation = async (slaId) => {
    confirmationSweetAlert({
      id: slaId,
      handleConfirmation: handleDeleteSla,
    });
  };

  const handleNameSort = (sortOrder, field) => {
    handleApplyFilter({ ...filterParams, sortingOrder: sortOrder, sortingField: field });
  };

  const onSearchAction = () => {
    handleApplyFilter({ ...filterParams, searchText: onSearchText });
  };

  const currentItems = slaList?.items || [];

  const viewSla = (slaId) => {
    setSla(slaId);
    setView(VIEW.VIEW);
    toggleModal();
  };

  const editSla = (slaId) => {
    setSla(slaId);
    setView(VIEW.EDIT);
    setIsOpen(true);
  };

  const createSla = () => {
    setView(VIEW.CREATE);
    setIsOpen(!isOpen);
    return <>Edit</>;
  };

  const onSuccessfulModal = () => {
    toggleModal();

    loadSlaList();
  };

  const getComponent = () => {
    if (view === VIEW.VIEW) {
      return <SlaViewContainer slaId={sla} setIsOpen={setIsOpen} editSla={editSla} />;
    } else if (view === VIEW.CREATE) {
      return <SlaFormContainer onSuccessfulModal={onSuccessfulModal} />;
    } else if (view === VIEW.EDIT) {
      return <SlaFormContainer slaId={sla} onSuccessfulModal={onSuccessfulModal} />;
    }
  };

  const toggleExportModal = () => {
    setIsExportModalOpen(!isExpotModalOpen);
  };

  const getExportComponent = () => {
    return <ExportForm onSuccessfulModal={onSuccessfulModal} filterParams={filterParams} />;
  };

  const createExport = () => {
    setIsExportModalOpen(!isExpotModalOpen);
  };

  return (
    <React.Fragment>
      {
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                SLA Lists
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have a total of {slaList?.pagination?.totalCount || 0} SLA.</p>
              </BlockDes>
              <div className="d-flex flex-wrap">
                <FilteredByList data={filterParams.createdAt} title={"Created At"} isMap={false} isStr={true} />
                <FilteredByList data={filterParams.updatedAt} title={"Updated At"} isMap={false} isStr={true} />
                <FilteredByList data={filterParams.searchText} title={"Search By"} isNotMap={true} isStr={true} />
              </div>
            </BlockHeadContent>

            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>

                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <Check permission={["getEntity", "manageEntity"]}>
                      <li>
                        <Button
                          disabled={slaList?.pagination?.totalCount === 0}
                          className={`btn btn-white btn-outline-light`}
                          type="export"
                          isLoading={exportLoading}
                          onClick={createExport}
                        >
                          <Icon name="download-cloud"> </Icon>
                          <span>Export</span>
                        </Button>
                      </li>
                    </Check>

                    <Check permission={["manageEntity"]}>
                      <li className="nk-block-tools-opt">
                        <Button color="primary" className="btn-icon" onClick={createSla}>
                          <Icon name="plus"></Icon>
                        </Button>
                      </li>
                    </Check>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
      }

      <Block>
        <DataTable className="card-stretch">
          {
            <div className="card-inner position-relative card-tools-toggle ">
              <div className="card-title-group ">
                <div className="card-tools"></div>
                <div className="card-tools mr-n1 ">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggle();
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <div id="asset-filter-btn" tag="a" className="btn btn-icon " onClick={() => sfState.toggle()}>
                      {isFilterApplied ? <div className="dot dot-primary"></div> : null}
                      <Icon name="filter-alt"></Icon>
                    </div>
                  </ul>
                </div>
              </div>
              <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active"
                      onClick={() => {
                        setSearchText("");
                        handleApplyFilter({ ...filterParams, searchText: "" });
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search by level"
                      ref={(el) => {
                        focusSearchRef.current = !onSearch ? el : null;
                      }}
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          onSearchAction();
                        }
                        if (e.keyCode === 27) {
                          setSearchText("");
                          handleApplyFilter({ ...filterParams, searchText: "" });
                          toggle();
                        }
                      }}
                    />
                    <Button className="search-submit btn-icon">
                      <Icon name="search" onClick={onSearchAction}></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          }
          {slaLoading ? <></> : <DataTableBody compact>
            <DataTableHead>
            <DataTableRow>
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "id"
                        ? handleNameSort("DESC", "id")
                        : handleNameSort("ASC", "id");
                    }}
                  >
                    ID
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "id");
                      }}
                      name={`${filterParams.sortingOrder === "ASC" && filterParams.sortingField === "id"
                          ? "caret-up-fill "
                          : "caret-up"
                        } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "id");
                      }}
                      name={`${filterParams.sortingOrder === "DESC" && filterParams.sortingField === "id"
                          ? "caret-down-fill"
                          : "caret-down"
                        }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>

              <DataTableRow>
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "name"
                        ? handleNameSort("DESC", "name")
                        : handleNameSort("ASC", "name");
                    }}
                  >
                    Level
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "name");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "name"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "name");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "name"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "description"
                        ? handleNameSort("DESC", "description")
                        : handleNameSort("ASC", "description");
                    }}
                  >
                    Description
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "description");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "description"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "description");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "description"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>

              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "updated_at"
                        ? handleNameSort("DESC", "updated_at")
                        : handleNameSort("ASC", "updated_at");
                    }}
                  >
                    UpdatedAt
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "updated_at");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "updated_at"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "updated_at");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "updated_at"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "created_at"
                        ? handleNameSort("DESC", "created_at")
                        : handleNameSort("ASC", "created_at");
                    }}
                  >
                    CreatedAt
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "created_at");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "created_at"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "created_at");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "created_at"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <Check permission={["manageEntity"]}>
                <DataTableRow>{/* <span className="sub-text">Actions</span> */}</DataTableRow>
              </Check>
            </DataTableHead>
            <Sidebar toggleState={showFilter}>
              <SlaListFilter />
            </Sidebar>
            {/*Head*/}

            {currentItems.length > 0 ? (
              currentItems.map((item, key) => {
                return (
                  <DataTableItem key={item.id}>
                    <DataTableRow size="md" className="d-flex justify-content-center">
                      <span className="noWrap">{item.id}</span>
                    </DataTableRow>

                    <DataTableRow>
                      <div
                        className="user-card dropdown-toggle"
                        onClick={() => {
                          viewSla(item.id);
                        }}
                      >
                        <div className="user-name">
                          <span
                          
                            title={item.name}
                            className="tb-lead noWrap"
                          >
                            {item.name}
                          </span>
                        </div>
                      </div>
                    </DataTableRow>
                    <DataTableRow size="md" className="d-flex justify-content-center">
                      <span
                        data-toggle="tooltip"
                        title={item.description}
                        style={{
                          display: "inline-block",
                          width: item.description?.length > 50 ? "350px" : "",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          cursor: "pointer",
                        }}
                      >
                        {item.description}
                      </span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span className="noWrap">{item.updated_at ? format(new Date(item?.updated_at || null), "dd/MM/yyyy") : null}</span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span className="noWrap">{item.created_at ? format(new Date(item?.created_at || null), "dd/MM/yyyy") : null}</span>
                    </DataTableRow>
                    <Check permission={["manageEntity"]}>
                      <DataTableRow className="d-flex justify-content-center">
                        <Button
                          onClick={() => {
                            editSla(item.id);
                          }}
                        >
                          {" "}
                          <Icon name="edit"></Icon>
                          <span>Edit</span>
                        </Button>

                        <Button onClick={() => handleDeleteConfirmation(item.id)}>
                          {" "}
                          <Icon name="trash"></Icon>
                          <span>Delete </span>
                        </Button>
                      </DataTableRow>
                    </Check>
                  </DataTableItem>
                );
              })
            ) : (
              <></>
            )}
          </DataTableBody>}

          <div className="card-inner">
            {slaList.pagination && slaList.pagination.totalCount > 0 ? (
              <PaginationComponent
                itemPerPage={pagination.size}
                totalItems={slaList.pagination.totalCount}
                paginate={slaContext.updateShowListPage}
                currentPage={slaList.pagination.currentPage}
                paginatePage={slaContext.updateShowListSize}
              />
            ) : (
              !slaLoading && (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )
            )}
          </div>
        </DataTable>
        {slaLoading && <Skeleton count={10} className="w-100" />}
      </Block>

      <ModalViewer title="SLA" isOpen={isOpen} toggleModal={toggleModal} size="sm" component={getComponent()} />
      <ModalViewer
        title="SLA - Select the Export Fields"
        isOpen={isExpotModalOpen}
        size="md"
        toggleModal={toggleExportModal}
        component={getExportComponent()}
      />
    </React.Fragment>
  );
};

export default SlaListCompact;
