import React, { useContext, useEffect } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import { MaintenanceContext } from "../../../../providers/Maintenance.provider";
import RemedialListCompact from "../components/RemedialList";
import { RemedialContext, withRemedialProvider } from "../RemedialProvider";
import { useLocation, useHistory } from "react-router-dom";

const RemedialMaintenanceContainer = (props) => {
  const location = useLocation().search;
  const history = useHistory();
  const status = new URLSearchParams(location).get("status");
  const plantId = new URLSearchParams(location).get("plantId");

  const { loadRemedialList } = useContext(RemedialContext);
  const { dashboardFilterStatus ,getTableSettings} = useContext(RemedialContext);
  const { loadFilterOptions ,loadAllUsers} = useContext(MaintenanceContext);

  const fetchRemedialTasksData = async () => {
    const settings = await getTableSettings({
      entityType: "REMEDIAL_MAINTENANCE",
    });

    if (settings) {
      if (dashboardFilterStatus || (!dashboardFilterStatus && !status)) {
        const queryParams = new URLSearchParams(location);
        if (queryParams.has("status")) {
          queryParams.delete("status");
          history.replace({
            search: queryParams.toString(),
          });
        }
        if (queryParams.has("plantId")) {
          queryParams.delete("plantId");
          history.replace({
            search: queryParams.toString(),
          });
        }
        await loadRemedialList(settings,null,status);
      }
      await loadFilterOptions()
      if (status && !dashboardFilterStatus) {
        loadRemedialList(settings,null, status);
      }
    }
  };

  useEffect(() => {
    fetchRemedialTasksData()
  }, []);

  useEffect(() => {
    loadFilterOptions();
  }, []);

  useEffect(() => {
    loadAllUsers()
  }, [])

  
  return (
    <React.Fragment>
      <Head title="Brighter App | Remedial Maintenance" />
      <Content>
        <RemedialListCompact />
      </Content>
    </React.Fragment>
  );
};

export default withRemedialProvider(RemedialMaintenanceContainer);
